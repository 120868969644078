.main {
  padding: var(--spacing-medium);
}

.stats h5 {
  font-size: var(--font-size-small);
  text-transform: uppercase;
}

.value {
  font-family: var(--font-family-bold);
  font-size: 48px;
  letter-spacing: -1.2px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
}

.unit {
  font-family: var(--font-family-regular);
  font-size: 32px;
}

.title {
  font-size: var(--font-size-small);
  text-transform: uppercase;
}

.trend-up,
.trend-down {
  margin: 0;
}

.trend-up {
  color: var(--color-graph-2);
}

.trend-down {
  color: var(--color-graph-4);
}

.trend-up span::before,
.trend-down span::before {
  background-size: contain;
  content: '';
  display: inline-block;
  height: 11px;
  margin: 0 8px 0 0;
  width: 11px;
}

.trend-up span::before {
  background-image: url('~assets/icons/trend-up.svg');
}

.trend-down span::before {
  background-image: url('~assets/icons/trend-down.svg');
}
