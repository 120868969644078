input[type='checkbox'] {
  border: 1px solid var(--color-foreground--60);
  border-radius: 2px;
  cursor: pointer;
  padding: var(--spacing-xs);
}

.popover {
  max-width: 100%;
  width: 600px;
}

.checkbox-row {
  align-items: center;
  display: flex;
  margin-bottom: var(--spacing-small);
  padding: var(--spacing-small);
}

.checkbox-row label {
  margin-left: var(--spacing-medium);
}

.form .save {
  display: none;
}

@media (min-width: 768px) {
  .form {
    column-gap: var(--spacing-xxl);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1240px) {
  .form {
    column-gap: 120px;
  }

  .form .save {
    top: 34px;
  }
}
