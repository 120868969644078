.subheader h3 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xl);
  padding-bottom: 0.75rem;
}

.subheader p {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  font-style: italic;
  padding-bottom: 0.75rem;
}

@media (min-width: 1024px) {
  .subheader p {
    max-width: 85%;
  }
}
