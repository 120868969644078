@keyframes pulse {
  0% {
    height: 0%;
    opacity: 1;
    width: 0%;
  }

  30% {
    opacity: 1;
  }

  100% {
    height: 100%;
    opacity: 0;
    width: 100%;
  }
}

.container {
  align-items: center;
  background-color: #1b1b1b;
  border-radius: var(--border-radius-primary);
  display: inline-flex;
  justify-content: center;
  padding: 4rem;
}

.model {
  display: inline-block;
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
}

.zone {
  background: #fff;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  transition-duration: 300ms;
  transition-property: background-color;
  transition-timing-function: ease;
  width: 18%;
}

/* 1:1 aspect ratio */
.zone::before {
  content: '';
  float: left;
  padding-top: 100%;
}

/* Pulse */
.zone::after {
  animation: pulse 1.5s infinite;
  background-color: #fff;
  border-radius: 100%;
  content: '';
  display: block;
  height: 20px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}

.zone.red {
  background-color: var(--color-risk-high);
}

.zone.red::after {
  background-color: var(--color-risk-high);
}

.zone.yellow {
  background-color: var(--color-risk-medium);
}

.zone.yellow::after {
  background-color: var(--color-risk-medium);
}

.zone.green {
  background-color: var(--color-risk-low);
}

.zone.green::after {
  background-color: var(--color-risk-low);
}

.zone.open::after {
  visibility: hidden;
}

.zone.left-foot {
  bottom: 4%;
  right: 26%;
}

.zone.left-knee {
  bottom: 29%;
  right: 30.5%;
}

.zone.lower-back {
  left: 38%;
  top: 38%;
}

.zone.right-foot {
  bottom: 8%;
  left: 25.5%;
}

.zone.right-knee {
  bottom: 28.5%;
  left: 26.5%;
}

@media (min-width: 1025px) {
  .popover {
    width: 600px;
  }
}

.header,
.grid {
  margin-bottom: var(--spacing-medium);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
}

.header h3 {
  font-size: 1.1rem;
  text-transform: uppercase;
}

.header p {
  font-size: 0.8rem;
}

.grid {
  --grid-height: 40px;

  display: grid;
  font-size: 0.75rem;
  gap: var(--spacing-medium);
  grid-template-columns: auto 1fr;
}

.labels,
.sliders {
  align-items: center;
  display: grid;
  grid-auto-rows: var(--grid-height);
}

.labels {
  text-align: right;
}

.readonly {
  cursor: unset;
}

.readonly::after {
  content: none;
}

.sliders {
  --line-color: #898989;
  --line-thickness: 1px;
  --line-offset: calc(var(--slider-thumb-dimension) * 0.5);
  --line-0-offset: calc(0% + var(--line-offset));
  --line-12-5-offset: calc(
    12.5% + var(--line-offset) - var(--slider-thumb-dimension) * 0.125
  );
  --line-25-offset: calc(
    25% + var(--line-offset) - var(--slider-thumb-dimension) * 0.25
  );
  --line-37-5-offset: calc(
    37.5% + var(--line-offset) - var(--slider-thumb-dimension) * 0.375
  );
  --line-50-offset: calc(
    50% + var(--line-offset) - var(--slider-thumb-dimension) * 0.5
  );
  --line-62-5-offset: calc(
    62.5% + var(--line-offset) - var(--slider-thumb-dimension) * 0.625
  );
  --line-75-offset: calc(
    75% + var(--line-offset) - var(--slider-thumb-dimension) * 0.75
  );
  --line-87-5-offset: calc(
    87.5% + var(--line-offset) - var(--slider-thumb-dimension) * 0.875
  );
  --line-100-offset: calc(
    100% + var(--line-offset) - var(--slider-thumb-dimension) * 1
  );

  background-image:
    /* Horizontal Lines */ repeating-linear-gradient(
      transparent 0% calc(50% - (var(--line-thickness) / 2)),
      var(--line-color) calc(50% - (var(--line-thickness) / 2))
        var(--line-thickness),
      transparent calc(50% + (var(--line-thickness) / 2)) 100%
    ),
    /* Vertical Line @ 0% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-0-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-0-offset)) var(--line-thickness),
        transparent calc(var(--line-0-offset) + var(--line-thickness)) 100%
      ),
    /* Vertical Line @ 12.5% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-12-5-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-12-5-offset)) 1px,
        transparent calc(var(--line-12-5-offset) + var(--line-thickness)) 100%
      ),
    /* Vertical Line @ 25% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-25-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-25-offset)) var(--line-thickness),
        transparent calc(var(--line-25-offset) + var(--line-thickness)) 100%
      ),
    /* Vertical Line @ 37.5% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-37-5-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-37-5-offset)) var(--line-thickness),
        transparent calc(var(--line-37-5-offset) + var(--line-thickness)) 100%
      ),
    /* Vertical Line @ 50% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-50-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-50-offset)) var(--line-thickness),
        transparent calc(var(--line-50-offset) + var(--line-thickness)) 100%
      ),
    /* Vertical Line @ 62.5% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-62-5-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-62-5-offset)) var(--line-thickness),
        transparent calc(var(--line-62-5-offset) + var(--line-thickness)) 100%
      ),
    /* Vertical Line @ 75% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-75-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-75-offset)) var(--line-thickness),
        transparent calc(var(--line-75-offset) + var(--line-thickness)) 100%
      ),
    /* Vertical Line @ 87.5% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-87-5-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-87-5-offset)) var(--line-thickness),
        transparent calc(var(--line-87-5-offset) + var(--line-thickness)) 100%
      ),
    /* Vertical Line @ 100% */
      linear-gradient(
        90deg,
        transparent 0%,
        transparent calc(var(--line-100-offset) - var(--line-thickness)),
        var(--line-color) calc(var(--line-100-offset)) var(--line-thickness),
        transparent calc(var(--line-100-offset) + var(--line-thickness)) 100%
      );
  background-size: 100% var(--grid-height);
  margin-bottom: var(--spacing-medium);
  position: relative;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.number {
  bottom: 0;
  left: 0;
  position: absolute;
  transform: translate(-50%, 1.25rem);
  user-select: none;
}

.number.value-0 {
  left: var(--line-0-offset);
}

.number.value-25 {
  left: var(--line-25-offset);
}

.number.value-50 {
  left: var(--line-50-offset);
}

.number.value-75 {
  left: var(--line-75-offset);
}

.number.value-100 {
  left: var(--line-100-offset);
}
