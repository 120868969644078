.table {
  width: 100%;
}

.table thead tr,
.table tr:nth-child(even) {
  background-color: #272630;
}

.table td,
.table th {
  border: 1px solid var(--color-border-primary);
  font-size: var(--font-size-xs);
  font-variant-numeric: tabular-nums;
  padding: var(--spacing-xxs) var(--spacing-xs);
  text-align: center;
}
