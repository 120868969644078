/* stylelint-disable selector-class-pattern */
.table-toolbar {
  composes: table-toolbar from '../List/List.module.css';
  grid-row-gap: var(--spacing-small);
  grid-template-columns: auto 1fr;
  margin: 0;
}

.table-container {
  overflow-x: hidden;
}

.pagination {
  background-color: var(--color-background);
}

@media (max-width: 1023px) {
  .attach-btn {
    grid-column: 1 / -1;
    width: 100%;
  }
}

@media (min-width: 1260px) {
  .table-container {
    width: 1260px;
  }
}
