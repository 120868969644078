.form {
  gap: var(--spacing-medium);
}
@media (min-width: 768px) {
  .form {
    flex-direction: row;
  }
}

.check {
  color: var(--color-success);
}

.result {
  border-top: 1px solid var(--color-border-primary);
  display: flex;
  gap: var(--spacing-medium);
  margin-top: var(--spacing-xl);
  padding-top: var(--spacing-xl);
}

.result .input {
  flex: 1;
}

.result .copy {
  color: var(--color-interactive-2);
  cursor: pointer;
  font-family: var(--font-family-bold);
  font-size: 1.375rem;
}

.copied {
  background: var(--color-interactive-2);
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgb(0 0 0 / 80%);
  color: var(--color-white);
  font-family: var(--font-family-bold);
  font-size: 0.75rem;
  padding: var(--spacing-xs);
  text-transform: uppercase;
}

.copied .arrow {
  color: var(--color-interactive-2);
}

input[type='radio'] {
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  position: relative;
  width: 18px;
}

input[type='radio']:checked::before {
  background-color: white;
  border-radius: 50%;
  bottom: 3px;
  content: '';
  display: inline-block;
  left: 3px;
  position: absolute;
  right: 3px;
  top: 3px;
}

.radio-field {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.password-field {
  border-top: 1px solid #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-top: 20px;
}

@media (min-width: 768px) {
  .password-field > div:first-child {
    flex-grow: 1;
  }

  .password-field {
    align-items: center;
    flex-direction: row;
  }
}
