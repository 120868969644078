/* stylelint-disable selector-class-pattern -- column classnames map AssessmentSortOptions enum */
.main thead,
.main thead tr,
.main tbody,
.main tbody tr {
  background-color: transparent;
}

.modal-table-header {
  align-items: center;
  border-bottom: 1px solid var(--color-border-primary);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-xxl);
  justify-content: space-between;
  padding: var(--spacing-medium) var(--spacing-small) var(--spacing-medium)
    var(--spacing-large);
}

.organization-info {
  align-items: center;
  display: flex;
  gap: var(--spacing-large);
}

.assessments-count {
  flex-grow: 1;
  margin: 0;
  text-align: center;
}

.search-bar {
  flex-grow: 1;
  flex-wrap: wrap;
  grid-row-gap: var(--spacing-large);
  justify-content: flex-end;
}

.table-bottom-toolbar > div {
  padding: 0;
}

.search-bar > div:first-child {
  justify-content: flex-end;
}

.selected-pills-container {
  align-items: center;
  border-bottom: 1px solid var(--color-border-primary);
  padding: var(--spacing-large);
}

.selected-pills-container li {
  display: inline-block;
}

.selected-pills-container li:first-child {
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xl);
  margin: 0 var(--spacing-small) 0 0;
  text-transform: uppercase;
}

.spinner {
  display: flex;
  justify-content: center;
  padding: var(--spacing-large);
}

.spinner svg {
  position: static;
  transform: none;
}

.table-bottom-toolbar {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: var(--spacing-small) var(--spacing-large);
}

.compare-btn {
  display: flex;
  justify-content: flex-end;
}

.table-container {
  height: auto;
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.main tbody tr:first-of-type,
.main tbody tr:first-of-type.expanded-view {
  background-color: var(--color-background);
  border: 1px solid var(--color-foreground);
  border-radius: var(--border-radius-primary);
}

.main tbody tr:first-of-type td:nth-of-type(2)::before {
  content: url('../../../../assets/icons/lock-small.svg');
}

.main tbody tr:first-of-type td:nth-of-type(2) {
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .modal {
    height: 100vh !important;
    width: 100vw !important;
  }
}

@media (min-width: 1024px) {
  .table-container {
    max-height: calc(100vh - 370px);
    min-width: 1000px;
    width: auto;
  }
}

/* tablet down */
@media (max-width: 1023px) {
  .main thead,
  .main tbody {
    background-color: transparent;
  }

  .main tbody {
    font-size: var(--font-size-small);
  }

  .selected-pills-container {
    align-items: baseline;
    border-bottom: 0;
    padding: var(--spacing-medium) var(--spacing-medium)
      calc(var(--spacing-medium) - var(--spacing-xs)) var(--spacing-medium);
  }

  .selected-pills-container li {
    margin: 0 var(--spacing-xxs) var(--spacing-xxs) 0;
  }

  .selected-pills-container li:not(:first-child) {
    background-color: var(--color-background--40);
  }
}

/* mobile down */
@media (max-width: 767px) {
  .main :global thead,
  .main :global td:not(.PlayerName):not(.expander):not(.expanded-view) {
    display: none;
  }
}
