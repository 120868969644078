:root {
  --compare-athleticism-table-width: calc(
    100vw - calc(var(--spacing-medium)) * 2
  );
}

.button {
  background-color: var(--color-button-action);
  border-radius: var(--border-radius-primary);
  color: var(--color-text-primary);
  cursor: pointer;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-small);
  letter-spacing: 0.34px;
  padding: var(--spacing-small) var(--spacing-medium);
  text-transform: uppercase;
  white-space: nowrap;
}

.button-container {
  display: flex;
}

.reset-button {
  margin-right: 0;
  padding-right: 0;
}

.modal {
  background-color: var(--color-inner-row);
  border-radius: var(--border-radius-modal);
  display: block;
  max-width: unset;
  overflow-y: hidden;
}

.modal h2 {
  font-family: var(--font-family-regular);
  margin: 0;
  padding: var(--spacing-medium) var(--spacing-large2);
  text-align: center;
  text-transform: none;
}

/* Mobile only */
@media (max-width: 425px) {
  .button-container {
    justify-content: space-between;
  }
}

/* Mobile and tablet */
@media (max-width: 1023px) {
  .modal {
    background-color: var(--color-inner-row);
    margin: 0;
    padding: 0;
  }

  .modal h2 {
    font-size: var(--font-size-small);
  }
}

@media (min-width: 1025px) {
  .modal {
    background-color: var(--color-background-header);
    height: calc(100vh - var(--spacing-xl));
    max-width: 1080px;
    min-width: 1000px;
    overflow-x: hidden;
    padding: 0;
    width: var(--compare-athleticism-table-width);
    width: auto;
  }

  .popover {
    width: 600px;
  }

  .button-container {
    border: none;
  }
}
