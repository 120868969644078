.form-section {
  background-color: var(--color-background--50);
}

.form {
  border-radius: var(--border-radius-primary);
  margin-bottom: 32px;
  padding: var(--spacing-large) var(--spacing-medium);
}

.fieldset {
  display: grid;
  gap: var(--spacing-large);
  margin-bottom: var(--spacing-large);
}

.fieldset > legend {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-medium);
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .form {
    padding: 38px var(--spacing-xl);
  }

  .fieldset {
    grid-template-columns: 1fr 1fr;
  }

  .address {
    grid-column: span 2;
  }
}
