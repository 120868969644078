/* keeps focus on archetypes while mousing over text or dots */
[data-archetype] [data-archetype] ~ *:not([data-archetype]) {
  pointer-events: none;
}

[data-archetype='BigPlus'] [data-archetype='BigPlus'] > path:first-child {
  fill: rgb(84 166 254 / 60%);
}

[data-archetype='MiscPerimeter']
  [data-archetype='MiscPerimeter']
  > path:first-child {
  fill: rgb(110 240 150 / 60%);
}

[data-archetype='KinematicMover']
  [data-archetype='KinematicMover']
  > path:first-child {
  fill: rgb(84 255 242 / 60%);
}

[data-archetype='HyperAthleticGuard']
  [data-archetype='HyperAthleticGuard']
  > path:first-child {
  fill: rgb(251 173 111 / 60%);
}

[data-archetype='ForceMover'] [data-archetype='ForceMover'] > path:first-child {
  fill: rgb(230 107 255 / 60%);
}

[data-archetype='Specimen'] [data-archetype='Specimen'] > path:first-child {
  fill: rgb(254 222 105 / 60%);
}

[data-archetype='TraditionalBig']
  [data-archetype='TraditionalBig']
  > path:first-child {
  fill: rgb(255 114 123 / 60%);
}

[data-archetype='BigPlus'] [data-archetype='BigPlus'] > path:last-child {
  stroke-width: 4px;
}

[data-archetype='MiscPerimeter']
  [data-archetype='MiscPerimeter']
  > path:last-child {
  stroke-width: 4px;
}

[data-archetype='KinematicMover']
  [data-archetype='KinematicMover']
  > path:last-child {
  stroke-width: 4px;
}

[data-archetype='HyperAthleticGuard']
  [data-archetype='HyperAthleticGuard']
  > path:last-child {
  stroke-width: 4px;
}

[data-archetype='ForceMover'] [data-archetype='ForceMover'] > path:last-child {
  stroke-width: 4px;
}

[data-archetype='Specimen'] [data-archetype='Specimen'] > path:last-child {
  stroke-width: 4px;
}

[data-archetype='TraditionalBig']
  [data-archetype='TraditionalBig']
  > path:last-child {
  stroke-width: 4px;
}

[data-archetype='TraditionalBig'].over > path:first-child {
  fill: rgb(255 114 123 /30%);
}

[data-archetype='BigPlus'].over > path:first-child {
  fill: rgb(84 166 254 / 30%);
}

[data-archetype='MiscPerimeter'].over > path:first-child {
  fill: rgb(110 240 150 / 30%);
}

[data-archetype='KinematicMover'].over > path:first-child {
  fill: rgb(84 255 242 / 30%);
}

[data-archetype='HyperAthleticGuard'].over > path:first-child {
  fill: rgb(251 173 111 / 30%);
}

[data-archetype='ForceMover'].over > path:first-child {
  fill: rgb(230 107 255 / 30%);
}

[data-archetype='Specimen'].over > path:first-child {
  fill: rgb(254 222 105 / 30%);
}

[data-archetype='TraditionalBig']
  [data-archetype='TraditionalBig'].over
  > path:first-child {
  fill: rgb(255 114 123 /30%);
}

[data-archetype='BigPlus'] [data-archetype='BigPlus'].over > path:first-child {
  fill: rgb(84 166 254 / 60%);
}

[data-archetype='MiscPerimeter']
  [data-archetype='MiscPerimeter'].over
  > path:first-child {
  fill: rgb(110 240 150 / 60%);
}

[data-archetype='KinematicMover']
  [data-archetype='KinematicMover'].over
  > path:first-child {
  fill: rgb(84 255 242 / 60%);
}

[data-archetype='HyperAthleticGuard']
  [data-archetype='HyperAthleticGuard'].over
  > path:first-child {
  fill: rgb(251 173 111 / 60%);
}

[data-archetype='ForceMover']
  [data-archetype='ForceMover'].over
  > path:first-child {
  fill: rgb(230 107 255 / 60%);
}

[data-archetype='Specimen']
  [data-archetype='Specimen'].over
  > path:first-child {
  fill: rgb(254 222 105 / 60%);
}

tspan {
  font-family: var(--font-family-regular);
}

.description.over {
  animation: fadein 0.25s ease-in-out;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.description {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  margin-top: var(--spacing-large);
  padding-right: var(--spacing-xl);
}

.description h2 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-large);
  margin-bottom: var(--spacing-small);
}

.description p {
  line-height: var(--line-height-small);
  margin-bottom: var(--spacing-small);
}

.hide-axes text[text-anchor] {
  display: none;
}

.hide-axes g[clip-path='url(#c)'] {
  display: none;
}
