/* stylelint-disable selector-class-pattern -- column classnames map AssessmentSortOptions enum */

.main {
  background-color: var(--color-inner-row);
  display: grid;
  grid-gap: var(--spacing-medium) var(--spacing-xl);
  padding: var(--spacing-large) 0 0 0;
}

.left-column,
.right-column {
  display: grid;
  grid-row-gap: var(--spacing-large);
}

.assessment-date-list {
  padding-left: var(--spacing-medium);
  padding-right: var(--spacing-medium);
}

.assessment-date-list h5 {
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-xs);
  text-transform: uppercase;
}

.assessment-date-list span {
  display: inline-block;
  font-size: var(--font-size-medium);
  line-height: 1.5;
  width: 80px;
}

.assessment-date-list p {
  margin-bottom: 0;
}

.stats,
.measurements,
.scores {
  display: grid;
}

.injury-risk {
  display: none;
}

.stats,
.measurements {
  grid-template-columns: 1fr 1fr;
}

.measurements > div {
  border: 1px solid var(--color-border-tertiary);
  padding: var(--spacing-medium) 0;
  text-align: center;
}

.measurements > div:first-child {
  border-right: 0;
}

.measurement-type,
.measurement-value,
.score-type,
.score-value {
  margin: 0;
}

.measurement-type,
.score-type,
.performance-factors-title {
  text-transform: uppercase;
}

.measurement-value {
  font-size: var(--font-size-large);
}

.stats {
  grid-template-rows: repeat(2, 130px);
}

.training-targets {
  background-color: var(--color-background-secondary);
  border-color: var(--color-interactive-2);
  border-style: solid;
  border-width: 1px 0;
  margin-bottom: var(--spacing-large);
  padding: var(--spacing-large);
}

.training-targets h5,
.training-targets p {
  font-size: var(--font-size-small);
}

.training-targets h5 {
  letter-spacing: 0.62px;
  margin-bottom: var(--spacing-small);
  text-transform: uppercase;
}

.training-targets span {
  font-family: var(--font-family-bold);
}

.factor:last-child {
  margin: 0;
}

.scores {
  border-bottom: 1px solid var(--color-border-tertiary);
  display: flex;
  grid-gap: var(--spacing-large);
  justify-content: flex-end;
  padding-bottom: var(--spacing-xl);
}

.score {
  border: 1px solid var(--color-border-tertiary);
  border-radius: var(--border-radius-primary);
  display: flex;
  flex-direction: column;
  height: 148px;
  justify-content: center;
  padding: var(--spacing-large);
}

.score-type {
  font-size: var(--font-size-small);
}

.score-value {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xxxl);
}

.performance-factors {
  padding: var(--spacing-large) 0;
  text-align: center;
}

.performance-factors p {
  font-size: var(--font-size-xs);
}

.performance-factors-desc {
  color: var(--color-text-secondary);
  font-family: var(--font-family-italic);
  margin: 0 0 var(--spacing-large) 0;
}

.assessment-text {
  opacity: 0.5;
}

.assessment-text:hover {
  opacity: 0.75;
}

.assessment-text.selected {
  opacity: 1;
}

.Soccer .scores {
  display: none;
}

/* Mobile only */
@media (max-width: 767px) {
  .main {
    width: 100vw;
  }

  .measurements,
  .stats,
  .scores,
  .assessment-date-list {
    padding-left: var(--spacing-medium);
    padding-right: var(--spacing-medium);
  }

  .training-targets {
    padding-bottom: var(--spacing-xl);
    padding-top: var(--spacing-xl);
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1260px) {
  .main {
    width: 100vw;
  }

  .scores {
    justify-content: flex-start;
  }
}

/* Desktop only */
@media (min-width: 1260px) {
  .main {
    grid-row-gap: var(--spacing-xl);
    grid-template-columns: auto 220px auto;
    padding: 36px calc(var(--spacing-large) * 3);
    width: 1260px;
  }

  .injury-risk {
    align-content: flex-start;
    display: grid;
  }

  .injury-risk > div:first-child {
    background-color: transparent;
    padding: 0;
  }

  .assessment-date-list {
    border-bottom: 1px solid var(--color-border-tertiary);
    grid-column: 1 / span 3;
    padding-bottom: var(--spacing-medium);
  }

  .left-column {
    grid-template-rows: 72px auto;
  }

  .right-column {
    grid-template-rows: 200px auto;
  }

  .stats,
  .measurements {
    grid-auto-flow: row;
    grid-column: 1;
  }

  .training-targets {
    border: 1px solid var(--color-interactive-2);
    border-radius: var(--border-radius-primary);
    grid-row: 3;
    margin-bottom: unset;
    padding: var(--spacing-large);
  }
}
