.article {
  padding: var(--spacing-small);
}

.profile-form-container {
  background-color: var(--color-background-secondary);
  padding: var(--spacing-medium) var(--spacing-xs);
}

@media (min-width: 769px) {
  .article {
    column-gap: 32px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 0 auto;
    margin-bottom: 114px;
    max-width: 1200px;
    padding: 0;
  }

  .profile-form-container {
    padding: var(--spacing-xl);
  }
}
