@media (max-width: 1023px) {
  .hide-mobile {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

@media (min-width: 1240px) {
  .main {
    margin: 0 auto;
    max-width: 1920px;
    padding: 0;
  }
}

.d-flex {
  display: flex;
}

input[type='checkbox'] {
  border: 1px solid var(--color-foreground--60);
  border-radius: 2px;
  cursor: pointer;
  padding: 8px;
}

input[type='checkbox']:checked {
  background-color: var(--color-interactive);
  background-image: url('~assets/icons/checkmark-white.svg');
  background-size: contain;
  border: 1px solid var(--color-interactive);
  content: ' ';
  display: inline-block;
}
