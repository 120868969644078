.container {
  align-items: center;
  border-radius: var(--border-radius-primary);
  display: flex;
  justify-content: center;
  margin-bottom: 65%;
  padding: 4rem;
}

.model {
  display: inline-block;
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
}

@media (min-width: 1024px) {
  .container {
    display: inline-flex;
    margin-bottom: 0;
  }
}
