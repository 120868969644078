@media (max-width: 1023px) {
  .assessment-results-container .mobile-black {
    background-color: var(--color-background);
    margin: 0 calc(var(--spacing-large) * -1) var(--spacing-large)
      calc(var(--spacing-large) * -1);
    padding: var(--spacing-xl) var(--spacing-large);
  }

  .full-width-chart {
    padding: 0 5%;
  }
}

.assessment-results-container {
  background-color: var(--color-background-secondary);
  padding: var(--spacing-xl) var(--spacing-large);
}

.vert-standing,
.vert-drop,
.lateral-skater,
.accel-decel {
  border-bottom: 1px solid var(--color-border-primary);
}

.background-container {
  background-color: var(--color-background);
  margin-bottom: var(--color-border-primary);
}

.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.full-width-chart {
  width: 100%;
}

/* Mobile only */
@media (max-width: 425px) {
  .flex {
    display: block;
    margin-bottom: var(--spacing-large);
  }
}

@media (min-width: 1024px) {
  .assessment-results-container {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: 50%;
    width: 100vw;
  }

  .width-control {
    margin: 0 auto;
    max-width: 1260px;
  }

  .vert-standing,
  .vert-drop,
  .lateral-skater,
  .accel-decel {
    border-bottom: none;
  }

  .two-col-grid {
    /* align-items: center; */
    background-color: var(--color-background);
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: var(--spacing-large);
    padding: var(--spacing-large);
    padding-top: 0;
    width: 100%;
  }

  .top-margin {
    margin-top: var(--spacing-large);
  }

  .vert-drop,
  .accel-decel {
    position: relative;
  }

  .athleticism-graph {
    align-items: flex-start;
    flex: 1 40%;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    position: relative;
  }

  .full-width-chart {
    padding: 0 10%;
    width: 100%;
  }

  @media (min-width: 1024px) {
    .athleticism-graph:nth-child(1)::before {
      background: var(--color-border-primary);
      content: '';
      height: calc(100% - 4rem);
      position: absolute;
      right: -1rem;
      top: 2rem;
      width: 1px;
    }

    .athleticism-graph:nth-child(3)::before {
      background: var(--color-border-primary);
      bottom: 2rem;
      content: '';
      height: calc(100% - 4rem);
      position: absolute;
      right: -1rem;
      width: 1px;
    }

    .athleticism-graph:nth-child(3)::after {
      background: var(--color-border-primary);
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      top: -1rem;
      width: calc(100% - 2rem);
    }

    .athleticism-graph:nth-child(4)::after {
      background: var(--color-border-primary);
      content: '';
      height: 1px;
      position: absolute;
      right: 0;
      top: -1rem;
      width: calc(100% - 2rem);
    }
  }

  .athleticism-header {
    display: inline-block;
  }

  .background-container {
    background-color: var(--color-background);
    margin-bottom: 0;
    padding: var(--spacing-large2);
  }

  .graphs {
    border-right: 1px solid var(--color-border-primary);
    flex: 1;
    padding-right: var(--spacing-xl);
  }

  .horizontal-rule {
    color: var(--color-foreground-2--40);
  }

  .injury-risk-container {
    align-content: center;
    border-bottom: 1px solid var(--color-border-primary);
    border-top: 1px solid var(--color-border-primary);
    flex: 1;
    padding-top: var(--spacing-large2);
    position: sticky;
    top: 0;
  }

  .layout {
    align-items: flex-start;
    column-gap: var(--spacing-xl);
    display: flex;
    flex-wrap: nowrap;
  }
}
