/* stylelint-disable selector-class-pattern -- column classnames map AssessmentSortOptions enum

  Overrides for dashboard table
 */

.main {
  display: grid;
  width: 100vw;
}

.expander {
  max-width: 30px;
}

.view {
  width: auto;
}

/* mobile only */
@media (max-width: 767px) {
  .Last,
  .Type,
  .Position,
  .Count {
    display: none;
  }

  .select {
    max-width: 20px;
  }
}

@media (max-width: 1023px) {
  .PlayerName {
    max-width: 100px;
  }
}

/* From tablet and up */
@media (min-width: 768px) {
  .view {
    text-align: right;
  }
}

/* Desktop only */
@media (min-width: 1260px) {
  .main,
  .expanded-view-container {
    width: 1260px;
  }
}
