.drawer {
  background-color: #202020;
  border-radius: 0.5rem 0.5rem 0 0;
  bottom: 0;
  box-shadow: 0 0 1rem rgb(0 0 0 / 30%);
  left: 0;
  max-height: 100%;
  overflow: auto;
  padding: 2rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 999;
}

.popover {
  background-color: #202020;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgb(0 0 0 / 80%);
  max-width: 100vw;
  min-width: 350px;
  padding: 3rem;
}

.arrow {
  fill: #fff;
}

.close {
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 3rem;
}
