.terms {
  font-size: var(--font-size-small);
}

.terms dt {
  font-family: var(--font-family-bold);
}

.terms dt::after {
  content: ' - ';
}

.terms dt,
.terms dd {
  display: inline;
}

.terms > div {
  margin-bottom: var(--spacing-medium);
}
