.links {
  border-bottom: 1px solid var(--color-border-primary);
  border-top: 1px solid var(--color-border-primary);
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-large);
  padding: var(--spacing-medium);
}

.links button {
  font-size: var(--font-size-small);
  text-transform: none;
}

.links .dashboard {
  align-items: center;
  display: flex;
  font-size: var(--font-size-small);
}

.links .dashboard::before {
  content: url('~assets/icons/arrow-down.svg');
  transform: rotate(90deg);
}

.carousel .slide {
  text-align: left !important;
}

@media (min-width: 768px) {
  .links {
    border-bottom: none;
    padding: var(--spacing-medium) 24px;
  }

  .grid {
    display: grid;
    gap: var(--spacing-small);
    grid-template-columns: repeat(2, 1fr);
    padding: 0 var(--spacing-large);
  }
}

@media (min-width: 1024px) {
  .grid {
    display: flex;
    gap: 12px;
    justify-content: center;
    padding: 0 var(--spacing-large);
  }
}
