@font-face {
  font-family: D-DIN;
  src: url('../assets/fonts/D-DIN.woff2') format('woff2');
}
@font-face {
  font-family: D-DIN-Bold;
  src: url('../assets/fonts/D-DIN-Bold.woff2') format('woff2');
}
@font-face {
  font-family: D-DIN-Italic;
  src: url('../assets/fonts/D-DIN-Italic.woff2') format('woff2');
}
@font-face {
  font-family: D-DIN-Condensed;
  src: url('../assets/fonts/D-DINCondensed.woff2') format('woff2');
}
@font-face {
  font-family: D-DIN-Condensed-Bold;
  src: url('../assets/fonts/D-DINCondensed-Bold.woff2') format('woff2');
}
