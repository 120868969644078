.main {
  align-items: flex-start;
  border: 1px solid var(--color-interactive-2);
  border-radius: var(--border-radius-primary);
  display: grid;
  grid-row-gap: var(--spacing-medium);
  grid-template-columns: repeat(2, 1fr);
  padding: var(--spacing-large) calc(var(--spacing-large) + var(--spacing-xs));
}

.message {
  font-family: var(--font-family-regular);
}

.contact-btn {
  justify-self: end;
}
