.container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: var(--spacing-medium);
}

.previous,
.next {
  cursor: pointer;
}

.previous[disabled],
.next[disabled] {
  cursor: default;
  opacity: 0.5;
}

.previous svg {
  transform: rotate(180deg);
}
