.wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  height: calc(100vh - var(--header-height));
}

.container {
  margin-inline: auto;
  max-width: 1200px;
}

.header {
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-border-primary);
  font-size: var(--font-size-medium);
  padding-block: var(--spacing-medium);
}

.header .container {
  padding-inline: var(--spacing-medium);
}

.content {
  background-color: var(--color-dark);
}

.trigger {
  align-items: center;
  color: var(--color-text-secondary);
  display: flex;
  font-size: var(--font-size-medium);
  padding: var(--spacing-medium);
  text-transform: none;
  width: 100%;
}

.trigger .arrow {
  margin-right: var(--spacing-large);
}

.trigger[data-state='open'] {
  background-color: #1d1d1d;
}

.trigger[data-state='open'] .arrow {
  transform: rotate(90deg);
}

.answer {
  background-color: var(--color-inner-row);
  padding: var(--spacing-xl) var(--spacing-xxl);
}

.answer a {
  color: var(--color-button-action);
}

.answer p {
  line-height: 1.3;
  margin-block: 1rem;
}
