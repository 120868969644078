.sub-row {
  background-color: var(--color-background-header);
  display: flex;
  font-size: var(--font-size-small);
  gap: var(--spacing-large);
  padding: var(--spacing-large) 0 var(--spacing-large) var(--spacing-xxl);
  width: var(--a-table-default-width);
}

.sub-row:hover {
  background-color: var(--color-hover-row);
}

.sub-row:not(:last-child) {
  border-bottom: 1px solid var(--color-border-primary);
}

.scores > i {
  margin: 0 var(--spacing-xxs);
}

/* mobile only */
@media (max-width: 1023px) {
  .sub-row {
    background-color: transparent;
    padding: var(--spacing-medium);
    width: var(--compare-athleticism-table-width);
  }
}
