.button {
  --button-height: 56px;

  cursor: pointer;
  font-family: var(--font-family-bold);
  height: var(--button-height);
  padding: 0 var(--spacing-large);
  text-align: center;
  text-transform: uppercase;
}

.button:disabled {
  background-color: var(--color-button-disabled);
  cursor: default;
}

.primary {
  background-color: var(--color-interactive);
  border-radius: var(--border-radius-primary);
  color: var(--color-foreground);
  font-size: var(--font-size-medium);
  letter-spacing: 0.38px;
  letter-spacing: 0.34px;
  padding: 0.75rem 1.5rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.secondary {
  border: solid 1px var(--color-border-primary);
  border-radius: var(--border-radius-primary);
  color: var(--color-foreground);
  font-size: 20px;
  letter-spacing: 0.43px;
}

.tertiary {
  color: var(--color-text-secondary);
  cursor: pointer;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-small);
  font-weight: bold;
  height: auto;
  letter-spacing: 0.34px;
  opacity: 0.5;
  text-align: right;
  text-transform: uppercase;
}
