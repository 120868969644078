:root {
  --chart-size: 200px;
  --color-dark: #191919;
  --color-darker: var(--color-background);

  /* @tokens Colors
  @presenter Color */
  --color-warning: rgb(242 195 29 / 100%);
  --color-warning--10: rgb(242 195 29 / 10%); /* error banner inside */
  --color-success: rgb(37 162 73 / 100%);
  --color-success--10: rgb(37 162 73 / 10%);
  --color-error: rgb(219 32 42 / 100%);
  --color-error--10: rgb(219 32 42 / 10%);
  --color-info: rgb(2 68 207 / 100%);
  --color-foreground: rgb(255 255 255 / 100%); /* Primary text */
  --color-foreground--90: rgb(255 255 255 / 90%); /* Alt Text */
  --color-foreground--40: rgb(
    255 255 255 / 40%
  ); /* Button background for disabled and form label */

  --color-foreground--60: rgb(255 255 255 / 60%);
  --color-foreground--10: rgb(255 255 255 / 10%); /* Button label disabled */
  --color-foreground--4: rgb(255 255 255 / 4%);
  --color-foreground-2--40: rgb(151 151 151 / 40%); /* Border Assessment */
  --color-background: rgb(14 14 14 / 100%); /* Background */
  --color-background--90: rgb(14 14 14 / 90%); /* Lighter background */
  --color-background--50: rgb(14 14 14 / 50%); /* Form background */
  --color-background--40: rgb(14 14 14 / 40%); /* Profile header background */
  --color-interactive: rgb(66 105 225 / 100%); /* Primary Blue for buttons */
  --color-interactive-2: rgb(69 137 255 / 100%); /* Text Link Primary */
  --color-interactive-3: rgb(
    255 255 255 / 100%
  ); /* Secondary outline button color */

  --color-interactive-4: rgb(0 79 218 / 100%); /* Button Hover */
  --color-interactive-5: rgb(0 71 207 / 100%); /* Active */

  /* TODO: remove duplicates */
  --color-button-background-primary: #c8010b;
  --color-button-background-secondary: var(--color-background);
  --color-button-action: #4269e1;
  --color-button-disabled: #393939;
  --color-background-lighter: #131313;
  --color-background-secondary: #191919;
  --color-background-tertiary: #0e0e0e;
  --color-background-header: #151515;
  --color-border-primary: rgb(151 151 151 / 30%);
  --color-border-secondary: #fff;
  --color-border-tertiary: #464646;
  --color-text-primary: #fff;
  --color-text-secondary: #bbb;
  --color-text-tertiary: #6d6d70;
  --color-graph-1: #54a6fe;
  --color-graph-2: #6ef0a0;
  --color-graph-3: #fede69;
  --color-graph-4: #ff727b;
  --color-graph-5: #54fff2;
  --color-graph-6: #ff9948;
  --color-form: #202020;
  --color-error-red: #e14840;
  --color-risk-low: #6ef0a095;
  --color-risk-medium: #fede6995;
  --color-risk-high: #ff727b95;
  --color-inner-row: #222;
  --color-hover-row: #15181f;
  --color-toast-error: #db1f2a;
  --color-toast-error-background: #fff0f1;
  --color-toast-info: #0344cf;
  --color-toast-info-background: #ecf5ff;
  --color-toast-success: #25a248;
  --color-toast-success-background: #ddfbe5;
  --color-toast-warning: #f3c31a;
  --color-toast-warning-background: #fff8e0;

  /* @tokens Font Families
  @presenter FontFamily */
  --font-family-regular: 'D-DIN', sans-serif;
  --font-family-bold: 'D-DIN-Bold', sans-serif;
  --font-family-italic: 'D-DIN-Italic', sans-serif;
  --font-family-condensed-regular: 'D-DIN-Condensed', sans-serif;
  --font-family-condensed-bold: 'D-DIN-Condensed-Bold', sans-serif;
  --font-family-roboto: 'roboto', sans-serif;

  /* @tokens Font Sizes
  @presenter FontSize */
  --font-size-xxs: 12px;
  --font-size-xs: 14px;
  --font-size-small: 16px; /* base font size */
  --font-size-medium: 18px;
  --font-size-large: 24px;
  --font-size-xl: 28px;
  --font-size-xxl: 36px;
  --font-size-xxxl: 64px;

  /* @tokens Spacing
  @presenter Spacing */
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-small: 12px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --spacing-large2: 36px;
  --spacing-xl: 44px;
  --spacing-xxl: 80px;

  /* @tokens Border Radius
  @presenter BorderRadius */
  --border-radius-primary: 4px;
  --border-width-primary: 1px;
  --border-radius-modal: 8px;

  /* @tokens Line Heights
  @presenter LineHeight
  DEV NOTE: Line Height is always text size + 4 */
  --line-height-xxs: 16px;
  --line-height-xs: 18px;
  --line-height-small: 20px;
  --line-height-medium: 22px;
  --line-height-large: 28px;
  --line-height-xl: 32px;
  --line-height-xxl: 40px;
  --line-height-xxxl: 68px;

  /* @tokens Slider
  @presenter Slider */
  --slider-thumb-dimension: 20px;
  --slider-track-thickness: 5px;

  /* @tokens Icon
  @presenter Icon */
  --icon-width: 28px;
  --icon-height: 28px;

  /* @tokens Toastify
  @presenter Toastify */
  --toastify-color-info: var(--color-toast-info);
  --toastify-color-success: var(--color-toast-success);
  --toastify-color-warning: var(--color-toast-warning);
  --toastify-color-error: var(--color-toast-error);
  --toastify-color-progress-light: var(--color-toast-info);
  --toastify-font-family: inherit;

  /* @tokens Selected Pill
  @presenter SelectedPill */
  --selected-pill-border-radius: 16px;
  --selected-pill-background: #121212;
  --selected-pill-border-color: var(--color-foreground--10);
  --selected-pill-padding: var(--spacing-xxs) var(--spacing-small);
  --selected-pill-close-icon-color: var(--color-foreground--90);
  --selected-pill-icon-dimension: 14px;
  --selected-pill-icon-cross-width: 2px;

  /* @tokens Header
  @presenter Header
  */
  --header-height: 72px;
}
