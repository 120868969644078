.root {
  align-items: center;
  display: flex;
  height: var(--slider-thumb-dimension);
  position: relative;
  touch-action: none;
  user-select: none;
  width: 100%;
}

.track {
  background-color: #54a6fe70;
  border-radius: 9999px;
  flex-grow: 1;
  height: var(--slider-track-thickness);
  position: relative;
}

.range {
  background-color: var(--color-graph-1);
  border-radius: 9999px;
  height: 100%;
  position: absolute;
}

.thumb {
  all: unset;
  background-color: var(--color-graph-1);
  border-radius: calc(var(--slider-thumb-dimension) / 2);
  cursor: pointer;
  display: block;
  height: var(--slider-thumb-dimension);
  transition: box-shadow 300ms ease;
  width: var(--slider-thumb-dimension);
}

.thumb:hover {
  box-shadow: 0 0 0 calc(var(--slider-thumb-dimension) / 3) #54a6fe75;
}
