.main {
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-border-primary);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-medium);
  justify-content: center;
  padding: var(--spacing-small) var(--spacing-medium);
}

.title {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xxl);
  margin-bottom: var(--spacing-small);
}

.avatar {
  flex: 1 40%;
  margin: 0 auto;
}

.description {
  font-size: 20px;
}

.details-container {
  margin-bottom: var(--spacing-large);
}

.details {
  margin-bottom: var(--spacing-large);
}

.details > * {
  /* overrides normalize and main */
  margin-bottom: var(--spacing-xs);
}

.details > p {
  line-height: 24px;
}

.location {
  font-size: var(--font-size-small);
  margin-top: 10px;
}

.location::before {
  background-image: url('~assets/images/icon-location.svg');
  background-size: contain;
  content: '';
  display: inline-block;
  height: 24px;
  vertical-align: middle;
  width: 24px;
}

.totals {
  display: grid;
  gap: var(--spacing-large);
  grid-template-columns: 1fr auto 1fr;
  justify-items: start;
  margin-bottom: var(--spacing-large);
}

.count {
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  justify-content: space-between;
  text-align: center;
  text-transform: uppercase;
}

.count span {
  color: var(--color-text-tertiary);
  line-height: 2;
}

.count strong {
  color: var(--color-text-primary);
  display: block;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xxl);
}

.separator {
  border: 1px solid var(--color-border-primary);
}

.admin-buttons {
  display: flex;
  gap: var(--spacing-medium);
}

.attach-btn:disabled {
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .admin-buttons button {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .main {
    grid-row-gap: var(--spacing-large2);
    padding: var(--spacing-medium) var(--spacing-medium) var(--spacing-xxl);
  }

  .details-container {
    margin-left: var(--spacing-large);
  }
}

@media (min-width: 1260px) {
  .main {
    display: grid;
    font-size: var(--font-size-medium);
    grid-template-columns: 300px calc(900px - 36px);
    margin-bottom: 0;
    padding: var(--spacing-large2) var(--spacing-medium)
      calc(var(--spacing-large2) * 4);
  }

  .title {
    margin-top: 0;
  }

  .totals {
    gap: 48px;
    grid-template-columns: 200px 1px 200px;
    margin-bottom: var(--spacing-large2);
  }

  .count {
    font-size: var(--font-size-large);
    text-align: unset;
  }

  .count span {
    font-size: var(--font-size-small);
  }

  .count:first-of-type {
    padding-left: 0;
  }

  .count strong {
    font-size: 72px;
  }
}
