.chart {
  background-color: var(--color-background--secondary);
  min-height: 350px;
  width: 100%;
}

.chart > * {
  /* This is necessary for proper responsive resizing. */
  width: auto !important;
}
