.subheader {
  padding-bottom: var(--spacing-medium);
  padding-top: 64px;
}

.subheader h2 {
  font-family: var(--font-family-bold);
  font-size: 32px;
  padding-bottom: var(--spacing-medium);
}

.subheader p {
  font-family: var(--font-family-regular);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}

@media (min-width: 1024px) {
  .subheader p {
    max-width: 75%;
  }
}
