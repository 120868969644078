.terms {
  margin: 0 var(--spacing-medium);
}

.terms dt {
  font-family: var(--font-family-bold);
}

.terms dt::after {
  content: ' - ';
}

.terms > div {
  margin-bottom: var(--spacing-small);
}

.terms div dt,
.terms div dd {
  display: inline;
}

.title {
  margin-bottom: 20px;
  margin-top: var(--spacing-small);
  text-transform: uppercase;
}

.wrapper .drawer {
  padding: 0;
}
@media (min-width: 1024px) {
  .terms {
    margin: 0;
  }
}
