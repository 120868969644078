.arrow-container {
  text-align: right;
}

.name {
  padding: var(--spacing-medium) 0;
}

.profile-row {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.profile-row > span:first-child {
  display: none !important;
}

svg {
  vertical-align: middle;
}

.table-background {
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-primary);
}

.table-container {
  background-color: var(--color-background);
  padding: 0 var(--spacing-medium);
  position: relative;
}

.table-container thead tr {
  background-color: var(--color-background);
}

.create-account-button {
  position: absolute;
  right: var(--spacing-medium);
  text-align: right;
  top: 9px;
}

@media (min-width: 768px) {
  .table-container {
    border-radius: var(--border-radius-primary);
    padding: 0 var(--spacing-large);
  }

  .create-account-button {
    right: 26px;
  }

  .name {
    margin-left: var(--spacing-medium);
  }

  .profile-row > span:first-child {
    display: block !important;
  }
}

.remove {
  float: right;
  font-size: var(--font-size-medium);
  margin-bottom: 32px;
  padding: var(--spacing-medium);
}

@media (min-width: 1023px) {
  .table-background {
    padding: 0 var(--spacing-xl) var(--spacing-xl);
  }

  .remove-padding {
    padding-bottom: var(--spacing-medium);
    padding-top: var(--spacing-large2);
  }

  .remove-padding form {
    margin: 0;
  }

  .table-container {
    margin: 0 auto;
    max-width: 1200px;
    min-width: 1200px;
    padding: 0 var(--spacing-large2);
    padding: 0 var(--spacing-large);
  }

  .create-account-button {
    right: 32px;
    top: 12px;
  }
}
