.sidebar-card {
  background-image: url('~assets/images/logo-primary-p3.svg');
  background-position: right -82px bottom 0%;
  background-repeat: no-repeat;
  border: solid 0.9px var(--color-border-primary);
  border-radius: var(--border-radius-primary);
  min-height: 287px;
  padding: 28px 42px 50px;
}

@media (min-width: 769px) {
  .sidebar-card:first-of-type {
    max-width: 420px;
  }
}

.sidebar-card:first-of-type {
  background-image: url('~assets/images/logo-primary-p3.svg');
  background-position: right -82px bottom 100%;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.uppercase {
  color: #b1b1b1;
  font-size: var(--font-size-small);
  margin-bottom: 16px;
  text-transform: uppercase;
}

.sidebar-card h3 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-large);
  line-height: 1.47;
  margin-bottom: var(--spacing-xxs);
}

.text {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  margin-bottom: var(--spacing-medium);
}

.sidebar-card:last-of-type .text {
  border-bottom: solid 0.9px var(--color-border-primary);
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.contact-link {
  color: var(--color-button-action);
  display: block;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-medium);
  line-height: 1.05;
}

.contact-link:first-of-type {
  margin-bottom: var(--spacing-medium);
}

.faq {
  color: var(--color-text-primary);
  font-family: var(--font-family-bold);
  font-size: var(--font-size-small);
  text-transform: uppercase;
}

.arrow-right {
  border: solid var(--color-button-action);
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-right: 10px;
  padding: 5px;
  transform: rotate(-45deg);
}
