.profile-picture {
  border-radius: 50%;
  overflow: hidden;
}

.profile-initials {
  background-color: var(--color-interactive);
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.profile-picture img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
