.tooltip {
  align-items: center;
  background-color: var(--color-foreground);
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgb(0 0 0 / 50%);
  color: var(--color-background);
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  gap: 0.25rem;
  min-width: 125px;
  padding: 1rem;
  white-space: nowrap;
}

.tooltip small {
  font-size: 0.9rem;
}

.trigger-text {
  cursor: pointer;
}

.arrow {
  fill: var(--color-foreground);
}
