.button-container a {
  border: solid 1px var(--color-border-primary);
  border-radius: var(--border-radius-primary);
  color: var(--color-foreground);
  cursor: pointer;
  font-family: var(--font-family-bold);
  font-size: 20px;
  letter-spacing: 0.43px;
  padding: var(--spacing-medium) var(--spacing-large);
  text-align: center;
  text-transform: uppercase;
}

.container {
  margin: 0 var(--spacing-medium);
  min-width: none;
  text-align: left;
}

.composite {
  align-items: center;
  display: flex;
  justify-content: center;
}

.composite .text {
  font-size: 14px;
  letter-spacing: 0.3px;
  margin-right: var(--spacing-medium);
  text-transform: uppercase;
}

.composite .unit {
  font-size: var(--font-size-large);
  font-weight: var(--font-family-bold);
}

.date {
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-small);
  line-height: var(--line-height-xxs);
  padding: 7px 21px 6px 12px;
}

.flex {
  column-gap: var(--spacing-small);
  display: flex;
  margin-bottom: var(--spacing-large);
}

.graph-link {
  color: var(--color-interactive-4);
  cursor: pointer;
  font-family: var(--font-family-bold);
}

.score {
  border: 1px solid var(--color-border-tertiary);
  border-radius: var(--border-radius-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacing-large);
}

.name h1 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-large);
  margin-bottom: var(--spacing-xs);
  margin-top: 0;
}

.name {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.name select {
  border: solid 0.5px var(--color-border-primary);
  border-radius: var(--border-radius-primary);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-small);
  line-height: var(--line-height-xxs);
  padding-right: 21px;
}

.scores .text-score {
  border-bottom: 1px solid var(--color-border-primary);
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-small);
  width: 100%;
}

.scores .text-score.active {
  background-color: rgb(255 255 255 / 20%);
}

.scores .text-score p {
  margin-bottom: 0;
  text-transform: none;
}

.scores {
  margin-bottom: 40px;
}

.calc-title {
  border-bottom: 1px solid var(--color-border-primary);
  display: flex;
  font-size: var(--font-size-xs);
  justify-content: space-between;
  margin-bottom: var(--spacing-large);
  padding-bottom: var(--spacing-small);
  text-transform: uppercase;
}

.button-container {
  background-color: rgb(25 25 25 / 50%);
  border-bottom: 1px solid var(--color-border-primary);
  display: flex;
  justify-content: center;
  margin: 0 -16px 40px;
  padding: var(--spacing-large) 0;
}

.bar-graph-container {
  border-bottom: 1px solid var(--color-border-primary);
}

.bar-row {
  display: grid;
  grid-template-columns: 45% 5% 50%;
}

.bar-row .bar {
  background-color: rgb(84 166 254 / 50%);
  border: solid 1px var(--color-graph-1);
  height: 18px;
  justify-self: end;
}

.container-1 .bar {
  background-color: rgb(110 240 160 / 50%);
  border: solid 1px var(--color-graph-2);
}

.container-2 .bar {
  background-color: rgb(255 153 72 / 50%);
  border: solid 1px var(--color-graph-6);
}

.container-3 .bar {
  background-color: rgb(84 255 242 / 50%);
  border: solid 1px var(--color-graph-5);
}

.bar-row p:nth-child(2) {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-large);
  margin-left: var(--spacing-small);
  text-align: right;
}

.bar-row p:last-child {
  font-size: var(--font-size-small);
  letter-spacing: 0.34px;
  margin-left: var(--spacing-large);
  text-align: right;
}

.bar-width {
  max-width: 50%;
  min-width: 50%;
}

.trend-icon {
  background-size: cover;
  content: '';
  display: inline-block;
  height: 16px;
  margin: 0 8px 0 0;
  width: 16px;
}

.trend-up {
  background-image: url('~assets/icons/trend-up.svg');
}

.trend-down {
  background-image: url('~assets/icons/trend-down.svg');
}

@media (min-width: 768px) {
  .container {
    background-color: var(--color-background--40);
    border: solid 0.5px var(--color-border-primary);
    border-radius: var(--border-radius-primary);
    margin: 0;
    min-width: 445px;
    padding: 30px;
  }

  .button-container {
    background-color: var(--color-background--40);
    border-bottom: none;
    margin-bottom: 0;
  }
}
