.header-cell {
  font-family: var(--font-family-regular);
  font-size: var(--font-size-small);
  padding: var(--spacing-medium) 0;
  text-transform: uppercase;
}

.table-main {
  align-items: center;
  border-bottom: 1px solid var(--color-border-tertiary);
  font-size: var(--font-size-medium);
  table-layout: fixed;
  width: 100%;
}

.table-header,
.table-body {
  background-color: var(--color-background);
}

.table-header tr {
  background-color: var(--color-background-lighter);
}

.table-body tr.expanded-row {
  background-color: var(--color-background-secondary);
}

.table-body tr:not(.expanded-view) {
  border-top: 1px solid var(--color-border-tertiary);
}

.table-body tr:hover {
  background-color: var(--color-hover-row);
}

.table-cell {
  padding: var(--spacing-xxs);
  vertical-align: middle;
}

.table-footer {
  background-color: var(--color-background);
  border-bottom-left-radius: var(--border-radius-primary);
  border-bottom-right-radius: var(--border-radius-primary);
}

.table-footer td {
  border: 0;
}

.table-footer td:first-of-type,
.table-footer td:last-of-type {
  border-bottom-left-radius: var(--border-radius-primary);
}

.table-footer td:last-of-type {
  border-bottom-right-radius: var(--border-radius-primary);
}

/* Desktop only */
@media (min-width: 1260px) {
  .header-cell,
  .table-cell,
  .footer-cell {
    padding: var(--spacing-medium);
  }

  .header-cell {
    padding: 24px var(--spacing-medium);
  }
}
