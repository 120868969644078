.header {
  background-color: var(--color-background-header);
}

.header h1 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xxl);
  line-height: 0.98;
  margin-bottom: var(--spacing-small);
}

.header h2 {
  font-size: 20px;
  margin-bottom: var(--spacing-medium);
}

.location {
  align-items: center;
  display: flex;
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-small);
}

.location::before {
  content: url('~assets/images/icon-location.svg');
  display: block;
  height: 24px;
  margin-right: var(--spacing-xs);
  width: 24px;
}

.icon {
  clip-path: circle(100px at center);
  max-height: 200px;
}

.user-email {
  align-items: center;
  background-color: var(--color-button-action);
  display: flex;
  justify-content: center;
  min-width: 392px;
  padding: 24px 40px;
}

.user-email a {
  align-items: center;
  color: var(--color-text-primary);
  display: flex;
  font-family: var(--font-family-bold);
  font-size: 20px;
}

.user-email a::before {
  content: url('~assets/images/icon-email.svg');
  display: block;
  height: 32px;
  margin-right: var(--spacing-small);
  width: 32px;
}

.header-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
}

.header-info .user-email {
  grid-column: 1 / span 2;
  margin-bottom: 0;
  padding-bottom: var(--spacing-large);
  padding-top: var(--spacing-large);
}

.header-info li {
  border-left: solid 0.9px var(--color-border-primary);
  border-right: solid 0.9px var(--color-border-primary);
  border-top: solid 0.9px var(--color-border-primary);
  padding: var(--spacing-small) var(--spacing-large);
}

.tags {
  display: flex;
  margin-bottom: var(--spacing-large);
}

.tags li {
  border: 1px solid var(--color-foreground);
  border-radius: var(--border-radius-primary);
  font-size: var(--font-size-xs);
  margin-right: var(--spacing-xs);
  padding: 10px 18px;
  text-align: center;
}

.age,
.score {
  background-color: var(--color-background-secondary);
  color: var(--color-text-primary);
  font-size: var(--font-size-xs);
  min-width: 200px;
  padding: 14px 64px;
  text-align: center;
  text-transform: uppercase;
}

.age span,
.score span {
  color: var(--color-text-primary);
  display: block;
  font-family: var(--font-family-bold);
  font-size: 32px;
  margin-bottom: 5px;
  text-align: center;
}

.score {
  padding: 14px 54px;
}

.profile-picture-container {
  position: relative;
}

.upload {
  bottom: 10px;
  color: transparent;
  cursor: pointer;
  height: 45px;
  overflow: hidden;
  position: absolute;
  right: 10px;
  width: 45px;
}

.upload::-webkit-file-upload-button {
  visibility: hidden;
}

.upload::before {
  content: url('~assets/icons/camera.svg');
  display: block;
  height: 45px;
  position: absolute;
  width: 45px;
}

.upload:hover::before {
  border-color: var(--color-background);
}

.upload:active::before {
  background: linear-gradient(#e3e3e3, #f9f9f9);
}

.image-upload-error {
  padding-top: var(--spacing-small);
  position: unset;
  width: 200px;
}

.image-upload-error p {
  color: var(--color-error-red);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxs);
  letter-spacing: 0.4px;
  line-height: var(--line-height-xxs);
  padding-top: 0.5rem;
}

.no-org-error {
  color: var(--color-error-red);
  font-size: var(--font-size-small);
  letter-spacing: 0.4px;
  line-height: var(--line-height-small);
  padding-top: var(--spacing-medium);
}

@media (max-width: 769px) {
  .header {
    align-items: center;
  }

  .header h1 {
    text-align: center;
  }

  .no-org-error {
    padding: var(--spacing-medium);
  }

  .user-info,
  .user-stats {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .user-email {
    margin-bottom: 32px;
    padding: 30px 64px;
  }
}

@media (min-width: 769px) and (max-width: 1239px) {
  .header {
    margin: 0 -24px 32px;
    margin-bottom: 0;
  }

  .image-upload-error {
    position: absolute;
  }

  .user-info {
    padding: var(--spacing-large);
  }
}

@media (min-width: 769px) {
  .header {
    border-bottom: solid 0.9px var(--color-border-primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }

  .header-info {
    display: flex;
    justify-content: flex-end;
  }

  .user-info {
    display: flex;
    gap: 84px;
    margin-bottom: 42px;
    max-width: calc(100% - 30%);
  }
}

@media (min-width: 1240px) {
  .header {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    overflow-y: hidden;
    padding: var(--spacing-medium) 0 0;
    position: relative;
    right: 50%;
    width: 100vw;
  }

  .user-info,
  .header-info {
    margin: 0 auto;
    max-width: 1200px;
    min-width: 1200px;
  }
}
