.search-bar {
  align-items: center;
  display: flex;
}

.icon {
  background-image: url('~assets/icons/search.svg');
  background-size: cover;
  content: ' ';
  cursor: pointer;
  display: inline-block;
  height: 28px;
  margin: var(--spacing-xxs) 0 var(--spacing-xs) 0;
  width: 28px;
  z-index: 1;
}

.clear {
  background-image: url('~assets/icons/close.svg');
  display: none;
  height: 12px;
  margin-left: -20px;
  width: 12px;
}

.search-bar form {
  border-bottom: 4px solid var(--color-button-action);
  font-family: var(--font-family-regular);
  font-size: var(--font-size-medium);
  padding: 0;
  width: 0;
}

.search-bar input {
  width: 0;
}

.search-bar input[type='search' i]::-webkit-search-cancel-button {
  display: none;
}

.search-bar input::placeholder {
  font-family: var(--font-family-regular);
  line-height: inherit;
  vertical-align: baseline;
}

.open {
  margin: 0 var(--spacing-large2) 0 0;
}

.open .icon {
  margin-right: -30px;
}

.open form {
  flex-grow: 1;
  padding: 6px 8px 6px 38px;
  width: auto;
}

.open input {
  display: inline-block;
  width: 100%;
}

.open .clear {
  display: inline-block;
}
