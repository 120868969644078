.center {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

.inline-center {
  position: static;
  transform: none;
}

.heading-large {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xlarge);
  text-transform: uppercase;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.truncate-with-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline-cover {
  background-color: rgb(14 14 14 / 75%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
}

.form-container {
  align-items: left;
  position: relative;
}
