.chart {
  min-height: 350px;
  position: relative;
  width: 100%;
}

.chart > * {
  /* This is necessary for proper responsive resizing. */
  width: auto !important;
}

.chart::after {
  background-image: linear-gradient(
    var(--color-risk-low) 0%,
    var(--color-risk-low) 50%,
    var(--color-risk-medium) 50%,
    var(--color-risk-medium) 75%,
    var(--color-risk-high) 75%,
    var(--color-risk-high) 100%
  );
  content: '';
  inset: 60px 23px 29px 48px;
  mix-blend-mode: multiply;
  position: absolute;
}
