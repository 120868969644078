.disabled {
  pointer-events: none;
}

.form-section {
  background-color: var(--color-background--50);
}

.form {
  border-radius: var(--border-radius-primary);
  margin: 0 auto;
  padding: var(--spacing-medium) var(--spacing-medium);
  text-align: center;
}

.text-field {
  margin-bottom: var(--spacing-medium);
}

.fieldset > legend {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-medium);
  text-align: left;
}

.form-container {
  position: relative;
}

.fieldset select {
  background-image: url('~assets/icons/arrow-down.svg');
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-right: var(--spacing-small);
}

.loading {
  margin-top: calc(var(--spacing-xxs) * -1);
}

.buttons {
  display: flex;
}

.buttons button {
  width: 100%;
}

.modal-title {
  color: var(--color-text-secondary);
  font-family: var(--font-family-regular);
  font-size: 20px;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}

.profile-picture-container {
  margin: 0 auto;
  margin-bottom: 42px;
  position: relative;
  width: 180px;
}

.upload {
  bottom: 10px;
  color: transparent;
  cursor: pointer;
  height: 45px;
  overflow: hidden;
  position: absolute;
  right: 10px;
  width: 45px;
}

.upload::-webkit-file-upload-button {
  visibility: hidden;
}

.upload::before {
  content: url('~assets/icons/camera.svg');
  display: block;
  height: 45px;
  position: absolute;
  width: 45px;
}

.upload:hover::before {
  border-color: var(--color-background);
}

.upload:active::before {
  background: linear-gradient(#e3e3e3, #f9f9f9);
}

@media (min-width: 768px) {
  .form {
    padding: var(--spacing-large2) var(--spacing-xl);
  }

  .fieldset {
    grid-template-columns: 1fr 1fr;
  }
}
