.disabled {
  pointer-events: none;
}

.form-section {
  background-color: var(--color-background--50);
}

.form {
  border-radius: var(--border-radius-primary);
  margin: 0 auto;
  padding: var(--spacing-medium) var(--spacing-medium);
  text-align: center;
}

.text-field {
  margin-bottom: var(--spacing-medium);
}

.fieldset > legend {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-medium);
  text-align: left;
}

.fieldset select {
  background-image: url('~assets/icons/arrow-down.svg');
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-right: var(--spacing-small);
}

.loading {
  margin-top: calc(var(--spacing-xxs) * -1);
}

.buttons {
  display: flex;
}

.buttons button {
  width: 100%;
}

.modal-title {
  color: var(--color-text-secondary);
  font-family: var(--font-family-regular);
  font-size: 20px;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}

.notification-text {
  font-size: var(--font-size-small);
  text-align: left;
}

@media (min-width: 768px) {
  .form {
    padding: var(--spacing-large2) var(--spacing-xl);
  }

  .fieldset {
    grid-template-columns: 1fr 1fr;
  }
}
