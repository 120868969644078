/* stylelint-disable selector-class-pattern -- column classnames map AssessmentSortOptions enum */

.table-main {
  border-bottom: 1px solid var(--color-border-tertiary);
  font-size: var(--font-size-medium);
  position: relative;
  width: 100%;
}

.table-main th {
  background-color: var(--color-background-header);
  position: sticky;
  top: 0;
  z-index: 3;
}

.table-header,
.table-body {
  background-color: var(--color-background);
}

.table-header tr {
  background-color: transparent;
}

.table-body tr:not(.expanded-view) {
  border-top: 1px solid var(--color-border-tertiary);
}

.table-body tr:hover {
  background-color: var(--color-hover-row);
}

.table-footer {
  background-color: var(--color-background);
  border-bottom-left-radius: var(--border-radius-primary);
  border-bottom-right-radius: var(--border-radius-primary);
}

.table-footer td {
  border: 0;
}

.table-footer td:first-of-type,
.table-footer td:last-of-type {
  border-bottom-left-radius: var(--border-radius-primary);
}

.table-footer td:last-of-type {
  border-bottom-right-radius: var(--border-radius-primary);
}

.header-cell {
  font-size: var(--font-size-small);
  text-transform: uppercase;
}

.header-cell,
.table-cell {
  vertical-align: middle;
}

.header-cell,
.table-cell,
.footer-cell {
  padding: var(--spacing-medium) calc(var(--spacing-small) / 2);
}

input.not-allowed {
  cursor: not-allowed;
}

.sort-icon {
  content: ' ';
  display: inline-block;
  margin: 2px var(--spacing-small);
  visibility: hidden;
  width: 12px;
}

.sorted {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  visibility: visible;
}

.sorted-asc {
  border-bottom: 10px solid var(--color-text-primary);
  composes: sorted;
}

.sorted-desc {
  border-top: 10px solid var(--color-text-primary);
  composes: sorted;
  margin: 0 var(--spacing-small);
}

/* chevron */
.expand {
  background-image: url('~assets/icons/arrow-right.svg');
  background-size: cover;
  content: ' ';
  cursor: pointer;
  display: inline-block;
  height: 28px;
  vertical-align: middle;
  width: 28px;
}

.expanded {
  background-image: url('~assets/icons/arrow-down.svg');
}

tr.expanded-view:hover {
  background-color: transparent;
}

.view-link {
  border: 1px solid var(--color-border-tertiary);
  border-radius: var(--border-radius-primary);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xs);
  padding: var(--spacing-xs) var(--spacing-small);
  text-transform: uppercase;
}

.trend-icon {
  background-size: contain;
  content: '';
  cursor: pointer;
  display: inline-block;
  height: 11px;
  margin: 0 0 0 8px;
  width: 11px;
}

.trend-up {
  background-image: url('~assets/icons/trend-up.svg');
}

.trend-down {
  background-image: url('~assets/icons/trend-down.svg');
}

.PlayerName,
.Type {
  composes: truncate-with-ellipsis from '../../styles/utilities.css';
}

/* From tablet and up */
@media (min-width: 768px) {
  .select {
    max-width: 48px;
    padding-left: var(--spacing-large);
  }

  .Type {
    max-width: 120px;
  }
}

/* Desktop only */
@media (min-width: 1260px) {
  .header-cell,
  .table-cell,
  .footer-cell {
    padding: var(--spacing-medium);
  }

  .header-cell {
    padding: var(--spacing-large) var(--spacing-medium);
  }
}
