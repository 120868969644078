.checkbox-row {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  padding: var(--spacing-small);
}

.checkbox-row label {
  margin-left: var(--spacing-medium);
}

.form {
  margin-top: 20px;
  padding: 0 28px;
}

.form-error {
  color: var(--color-error-red);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxs);
  letter-spacing: 0.4px;
  line-height: var(--line-height-xxs);
  padding-top: 0.5rem;
}

.form .save {
  margin-bottom: var(--spacing-large);
  margin-right: var(--spacing-medium);
}

.form-submit {
  grid-column: span 2;
  margin-left: auto;
  margin-right: 0;
}

@media (min-width: 768px) {
  .form {
    column-gap: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1240px) {
  .form {
    column-gap: 120px;
  }

  .form .save {
    top: 26px;
  }
}
