.article {
  padding: var(--spacing-small);
}

.button {
  --button-height: 56px;

  border: solid 1px var(--color-border-primary);
  border-radius: var(--border-radius-primary);
  color: var(--color-foreground);
  cursor: pointer;
  font-family: var(--font-family-bold);
  font-size: 20px;
  height: var(--button-height);
  letter-spacing: 0.43px;
  padding: 0 var(--spacing-large);
  text-align: center;
  text-transform: uppercase;
}

.button:disabled {
  background-color: var(--color-button-disabled);
  cursor: default;
}

.cancel {
  margin-right: var(--spacing-medium);
}

.confirmation-button-container {
  margin-top: var(--spacing-xl);
}

.profile-form-container {
  background-color: var(--color-background-secondary);
  padding: var(--spacing-medium) var(--spacing-xs);
}

.remove-account-button-container {
  text-align: right;
}

@media (min-width: 769px) {
  .article {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0;
  }

  .profile-form-container {
    padding: var(--spacing-xl);
  }
}
