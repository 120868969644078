.settings {
  background-color: #000;
  border: 1px solid #404040;
  border-radius: 3px;
  font-size: var(--font-size-xs);
  min-width: 280px;
}

.settings > div {
  padding: var(--spacing-medium);
}

.settings > div:not(:last-child) {
  border-bottom: 1px solid #404040;
  padding-bottom: var(--spacing-medium);
}

.settings > div:first-child {
  padding: var(--spacing-large) var(--spacing-medium);
}

.user {
  align-items: center;
  column-gap: var(--spacing-medium);
  display: flex;
}

.header {
  color: var(--color-text-secondary);
  margin-left: 8px;
}

.username,
.organization-name {
  color: var(--color-text-primary);
  font-family: var(--font-family-bold);
  font-size: var(--font-size-small);
}

.header-org-name {
  color: var(--color-text-secondary);
  font-size: 15px;
  padding: 3px 0;
}

.icons-container button {
  cursor: pointer;
  text-transform: none;
}

.icons-container li {
  font-size: var(--font-size-small);
  padding: var(--spacing-xs) 0;
}

.buttons button {
  width: 100%;
}

.settings nav svg {
  width: 30px;
}

.settings nav a,
.settings nav button {
  align-items: center;
  column-gap: 10px;
  display: flex;
}

.buttons {
  display: flex;
}

.buttons button:first-of-type {
  margin-right: 20px;
}

.accounts-header {
  color: var(--color-text-secondary);
  padding-bottom: var(--spacing-small);
  padding-left: var(--spacing-xxs);
  text-transform: uppercase;
}

.organization {
  align-items: center;
  border-bottom: 1px solid #404040;
  column-gap: 12px;
  cursor: pointer;
  display: flex;
  padding: 10px var(--spacing-small);
}

.role-container {
  flex: 1 1 30%;
}

.role {
  color: var(--color-text-secondary);
  font-size: var(--font-size-xs);
  padding-top: var(--spacing-xxs);
}

.organization:first-of-type {
  border-top: 1px solid #404040;
}

.selected-icon {
  width: 24px;
}

.selected-true {
  background-image: url('~assets/icons/active-checkmark.svg');
  background-size: cover;
  content: '';
  display: inline-block;
  height: 24px;
  margin: 0 0 0 4px;
  text-align: right;
  width: 24px;
}
