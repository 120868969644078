.button {
  background-color: var(--color-button-action);
  border-radius: 0.25rem;
  color: var(--color-text-primary);
  cursor: pointer;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-small);
  letter-spacing: 0.34px;
  padding: var(--spacing-small) var(--spacing-medium);
  text-transform: uppercase;
  white-space: nowrap;
}

.button-container {
  border-bottom: 1px solid var(--color-border-primary);
  padding-bottom: var(--spacing-large);
}

.modal {
  max-width: unset;
  overflow-x: hidden;
  padding: 0;
  width: 1080px;
}

.modal h2 {
  font-family: var(--font-family-regular);
  margin: 0;
  padding: var(--spacing-medium) var(--spacing-large2);
  text-transform: none;
}

.reset-button {
  margin-right: 0;
  padding-right: 0;
}

@media (min-width: 1025px) {
  .popover {
    width: 600px;
  }

  .button-container {
    align-content: center;
    align-items: baseline;
    border-bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 0;
  }
}
