.pill {
  background-color: var(--color-foreground--4);
  border: 1px solid var(--selected-pill-border-color);
  border-radius: var(--selected-pill-border-radius);
  display: inline-block;
  font-family: var(--font-family-regular);
  font-size: var(--font-size-xs);
  margin-right: var(--spacing-xxs);
  padding: var(--selected-pill-padding);
}

.pill > span {
  vertical-align: middle;
}

.remove {
  vertical-align: middle;
}

.icon {
  background-color: var(--selected-pill-close-icon-color);
  border-radius: calc(var(--selected-pill-icon-dimension) / 2);
  cursor: pointer;
  display: inline-block;
  height: var(--selected-pill-icon-dimension);
  margin: var(--spacing-xxs) 0 var(--spacing-xxs) var(--spacing-xs);
  padding: 2px;
  position: relative;
  vertical-align: inherit;
  width: var(--selected-pill-icon-dimension);
}

.icon::before,
.icon::after {
  background-color: var(--color-background);
  content: '';
  height: calc(
    var(--selected-pill-icon-dimension) -
      (var(--selected-pill-icon-cross-width) * 2)
  );
  left: 6px;
  position: absolute;
  width: var(--selected-pill-icon-cross-width);
}

.icon::before {
  transform: rotate(45deg);
}

.icon::after {
  transform: rotate(-45deg);
}

.icon:hover {
  opacity: 0.5;
}
