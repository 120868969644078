.overlay {
  background-color: rgb(0 0 0 / 50%);
  inset: 0;
  position: fixed;
}

.modal {
  background-color: var(--color-background);
  color: var(--color-text-primary);
  font-size: 1.125rem;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding: 3rem 1.25rem 1.25rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}

@media (min-width: 768px) {
  .modal {
    border: 1px solid var(--color-border-primary);
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem rgb(0 0 0 / 80%);
    font-size: 1.25rem;

    /* height: auto; */
    left: 50%;

    /* max-height: calc(100vh - var(--spacing-xl)); */
    max-width: 767px;
    padding: 3rem;
    top: 50%;
    transform: translate(-50%, -50%);

    /* width: calc(100vw - var(--spacing-xl)); */
  }
}

@media (min-width: 1024px) {
  .modal {
    height: auto;
    max-height: calc(100vh - var(--spacing-xl));

    /* width: calc(100vw - var(--spacing-medium)); */
  }
}

.close {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 3rem;
}

.title {
  border-bottom: 1px solid var(--color-border-primary);
  font-family: var(--font-family-bold);
  font-size: 1.25rem;
  margin-bottom: var(--spacing-large);
  padding-bottom: var(--spacing-small);
  text-transform: uppercase;
}
