/* assessment list view main container */
.main {
  background-color: var(--color-background-secondary);
  overflow-x: auto;
  width: inherit;
}

.table-toolbar {
  align-items: center;
  background-color: var(--color-background);
  border-top-left-radius: var(--border-radius-primary);
  border-top-right-radius: var(--border-radius-primary);
  column-gap: var(--spacing-xxs);
  display: grid;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-large);
  padding: var(--spacing-small) var(--spacing-medium) var(--spacing-small);
  width: 100vw;
}

.table-toolbar h2 {
  font-size: var(--font-size-xl);
}

select {
  cursor: pointer;
}

.spinner {
  display: flex;
  justify-content: center;
  min-height: 200px;
  position: relative;
}

.spinner svg {
  margin: 0;
  position: static;
}

.table-toolbar select {
  padding-left: 0;
}

.table-toolbar-empty {
  grid-column: 1 / -1;
  grid-row: 4;
}

.search-bar {
  justify-self: end;
}

.selected-rows {
  display: flex;
  gap: var(--spacing-medium);
  grid-row: 4;
  justify-content: center;
}

.selected-rows-label {
  color: var(--color-interactive-2);
  font-family: var(--font-family-bold);
  font-size: var(--font-size-small);
  line-height: var(--line-height-large);
  text-transform: uppercase;
}

button.compare-btn {
  font-size: var(--font-size-small);
  margin: 0;
  padding: var(--spacing-small) var(--spacing-large);
}

.compare-btn:disabled {
  cursor: not-allowed;
}

.table-container {
  background-color: var(--color-background);
  position: relative;
  width: 100vw;
}

/* mobile only */
@media (max-width: 767px) {
  .table-toolbar {
    display: flex;
    flex-wrap: wrap;
    row-gap: var(--spacing-xxs);
    width: inherit;
  }

  .table-toolbar h2 {
    margin-bottom: var(--spacing-small);
  }

  .search-bar {
    flex-grow: 1;
    grid-column: 2;
    margin: 0;
  }

  .search-bar > div:first-child {
    justify-content: flex-end;
  }

  button.compare-btn {
    margin-bottom: var(--spacing-small);
    padding: var(--spacing-small) var(--spacing-large);
    width: 100%;
  }

  .selected-rows {
    grid-row: 4;
  }

  .selected-rows li {
    margin-bottom: var(--spacing-xxs);
  }
}

/* From tablet and up  */
@media (min-width: 768px) {
  .main {
    display: grid;
    justify-items: center;
    padding: 0 0 var(--spacing-large2);
  }

  .summary-container {
    width: 100%;
  }

  .table-toolbar {
    grid-row-gap: var(--spacing-xs);
    grid-template-columns: auto 200px;
    margin-top: -80px;
  }

  .selected-rows,
  .compare-btn {
    grid-row: 2;
  }

  .compare-btn {
    grid-column: 2;
  }

  .selected-rows {
    align-items: center;
    justify-content: start;
  }
}

/* desktop only */
@media (min-width: 1200px) {
  .main {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  h2 {
    grid-column: 1;
    grid-row: 1;
  }

  .table-toolbar {
    grid-template-columns: auto 1fr minmax(auto, 200px) auto;
    width: max(1200px, 1260px);
  }

  .table-container {
    width: 1260px;
  }

  .selected-rows,
  .compare-btn {
    grid-row: 1;
  }

  .search-bar {
    grid-column: 3;
    margin-right: var(--spacing-large);
  }

  .compare-btn {
    grid-column: 4;
    height: 44px;
  }

  .selected-rows {
    grid-column: 2;
    grid-row: 1;
    margin-left: 60px;
  }
}
