.archetype a {
  color: var(--color-interactive-2);
}

.archetype li {
  line-height: var(--line-height-medium);
  margin-bottom: 5px;
}

.archetype ul {
  list-style: initial;
  margin: var(--spacing-large) 0 var(--spacing-large) 14px;
  padding: 0;
}

.header {
  margin-bottom: var(--spacing-xl);
  text-align: center;
}

.header h2 {
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-small);
  text-transform: uppercase;
}

.header p {
  color: #bbb;
  font-size: var(--font-size-xs);
  font-style: italic;
  margin: auto;
  max-width: 750px;
  text-align: center;
}

.legend {
  align-items: center;
  bottom: 0;
  column-gap: var(--spacing-xs);
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translateY(-50%, -50%);
}

.visual {
  position: relative;
  user-select: none;
}

.archetype [data-archetype] {
  cursor: pointer;
  fill: rgb(0 0 0 / 1%);
}

.archetype [data-archetype] > path:first-child {
  transition: fill 0.25s ease-in-out;
}

@media (min-width: 1024px) {
  .archetype {
    background-color: var(--color-background);
    padding-top: var(--spacing-xl);
  }

  .layout {
    align-items: flex-start;
    column-gap: var(--spacing-xl);
    display: flex;
    margin-bottom: var(--spacing-xl);
    padding-bottom: var(--spacing-xl);
  }

  .layout > div:first-child {
    flex: 2;
  }

  .layout > div:last-child {
    flex: 1;
  }
}

.toggle {
  --toggle-height: 18px;
  --toggle-handle-radius: 12px;

  display: inline-block;
  height: var(--toggle-height);
  position: relative;
  width: 32px;
}

.toggle input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background-color: #3f3e3f;
  border-radius: var(--toggle-height);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider::before {
  background-color: #000;
  border-radius: 50%;
  bottom: 3px;
  content: '';
  height: var(--toggle-handle-radius);
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: var(--toggle-handle-radius);
}

.toggle input:checked + .slider {
  background-color: #3f3e3f;
}

.toggle input:focus + .slider {
  box-shadow: 0 0 1px #3f3e3f;
}

.toggle input:checked + .slider::before {
  transform: translateX(var(--toggle-handle-radius));
}
