.container {
  --input-height: 56px;

  position: relative;
  text-align: left;
}

.element {
  background-color: var(--color-form);
  border-radius: var(--border-radius-primary);
  color: var(--color-foreground--90);
  display: flex;
  flex-direction: column;
  height: var(--input-height);
  padding: 0 var(--spacing-medium);
  position: relative;
  width: 100%;
}

select.element {
  line-height: calc(var(--input-height) - var(--spacing-xs));
}

.has-label .element {
  padding: var(--spacing-xs) var(--spacing-medium) 0;
}

.focused .element {
  box-shadow: inset 0 0 0 1px var(--color-interactive-2);
}

.errored .element {
  box-shadow: inset 0 0 0 1px var(--color-error-red);
}

.element:-webkit-autofill {
  -webkit-text-fill-color: rgb(255 255 255 / 60%) !important;
}

.element:-webkit-autofill,
.element:-webkit-autofill:hover,
.element:-webkit-autofill:focus,
.element:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--color-form) inset !important;
}

.label {
  color: var(--color-foreground--60);
  font-size: var(--font-size-small);
  left: var(--spacing-medium);
  letter-spacing: 0.15px;
  line-height: var(--input-height);
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: 0.2s;
}

.filled .label,
.focused .label {
  font-size: var(--font-size-xxs);
  line-height: calc(var(--input-height) / 2);
  top: 0;
}

.focused .label {
  color: var(--color-interactive-2);
}

.errored .label {
  color: var(--color-error-red);
}

.icon {
  pointer-events: none;
  position: absolute;
  right: var(--spacing-medium);
  top: var(--spacing-medium);
}

.error,
.info {
  font-size: 0.8rem;
  padding-left: var(--spacing-medium);
  transition: 0.2s;
}

.info {
  color: var(--color-foreground--60);
}

.focused .info {
  color: var(--color-interactive-2);
}

.error {
  color: var(--color-error-red);
}
