.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0 auto;
  max-width: 1023px;
  padding-inline: var(--spacing-large);
  padding-top: var(--spacing-xxl);
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .container {
    height: auto;
    min-height: 720px;
  }
}

.header,
.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
}

.header {
  font-size: 1.125rem;
  margin-bottom: var(--spacing-medium);
}

.header h1,
.header p {
  margin: 0;
}

.header h1 {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .header h1 {
    font-size: 2.5rem;
  }
}

.header .icon {
  height: 2.75rem;
  width: 2.75rem;
}
@media (min-width: 768px) {
  .header .icon {
    height: 4rem;
    width: 4rem;
  }
}

.main {
  flex: 1;
  margin-bottom: var(--spacing-xxl);
}

.main .form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  width: 100%;
}
@media (min-width: 768px) {
  .main .form {
    max-width: 378px;
  }
}

.footer {
  border-top: 1px solid var(--color-border-primary);
  display: flex;
  justify-content: center;
  padding-block: var(--spacing-xl);
  width: 100%;
}
