.disabled {
  pointer-events: none;
}

.form-section {
  background-color: var(--color-background--50);
}

.form,
.table-container {
  border-radius: var(--border-radius-primary);
  margin-bottom: 32px;
  padding: var(--spacing-medium);
}

.fieldset {
  display: grid;
  gap: var(--spacing-large);
  margin-bottom: var(--spacing-large);
}

.fieldset > legend {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-medium);
  text-transform: uppercase;
}

.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-xxs);
}

.flex legend {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  margin-bottom: 0;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .form {
    padding: var(--spacing-large) var(--spacing-medium);
  }

  .fieldset {
    grid-template-columns: 1fr 1fr;
  }

  .address {
    grid-column: span 2;
  }
}

@media (min-width: 1024px) {
  .form,
  .table-container {
    padding: var(--spacing-large) var(--spacing-medium);
  }
}
