.med-score {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xxxl);
}

.score {
  font-family: var(--font-family-bold);
  font-size: 80px;
}

.title {
  cursor: pointer;
  font-family: var(--font-family-regular);
  font-size: var(--font-size-small);
  text-transform: uppercase;
}

.trend-icon {
  background-size: cover;
  content: '';
  display: inline-block;
  height: 24px;
  margin: 0 0 0 var(--spacing-xxs);
  width: 24px;
}

.trend-up {
  background-image: url('~assets/icons/trend-up.svg');
}

.trend-down {
  background-image: url('~assets/icons/trend-down.svg');
}

@media (min-width: 769px) and (max-width: 1023px) {
  .score {
    align-items: baseline;
    display: flex;
  }

  .trend-icon {
    height: 20px;
    width: 20px;
  }
}
