.button {
  background-color: var(--color-interactive);
  border-radius: var(--border-radius-primary);
  color: var(--color-foreground);
  font-family: var(--font-family-bold);
  font-size: var(--font-size-medium);
  letter-spacing: 0.38px;
  letter-spacing: 0.34px;
  padding: var(--spacing-small) var(--spacing-medium);
  position: absolute;
  right: var(--spacing-large);
  text-align: center;
  text-transform: uppercase;
  top: 18px;
  white-space: nowrap;
}

.assign {
  height: auto;
}

.popover {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 0;
}

.header {
  align-items: center;
  border-bottom: 1px solid var(--color-border-primary);
  display: flex;
  font-family: var(--font-family-bold);
  font-size: 1.5rem;
  justify-content: space-between;
  padding: var(--spacing-large);
  padding-right: 50px;
}
@media (min-width: 1025px) {
  .header {
    font-size: 1.25rem;
  }

  .popover {
    border: solid 0.5px var(--color-interactive);
    border-radius: var(--border-radius-primary);
    margin-top: -16px;
  }
}

.content {
  overflow-y: auto;
  padding: var(--spacing-large);
}

.suggested {
  color: var(--color-text-secondary);
  display: inline-block;
  font-family: var(--font-family-italic);
  font-size: var(--font-size-xs);
}
