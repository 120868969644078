/* stylelint-disable selector-class-pattern */
body {
  background-color: var(--color-background);
  color: var(--color-text-primary);
  font-family: var(--font-family-regular), system-ui, -apple-system, 'Segoe UI',
    Roboto, sans-serif;
  font-size: var(--font-size-medium);
}

/*
  This is to fix an issue with Windows Chrome
  having a white text on white background...
*/
option {
  color: initial;
}

code {
  font-size: 12px;
}

pre {
  all: revert;
  line-height: 1;
  padding: var(--spacing-small);
}

hr {
  background-color: var(--color-border-primary);
  height: 1px;
  margin: var(--spacing-medium) 0;
}

p {
  margin-bottom: var(--spacing-medium);
}

.main-title {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xlarge);
}

svg {
  /* stylelint-disable-next-line value-keyword-case */
  fill: currentColor;
}

[hidden] {
  display: none;
}

.vis-hide {
  visibility: hidden;
}

button {
  cursor: pointer;
  text-transform: uppercase;
}

select {
  background-image: url('~assets/icons/arrow-down.svg');
  background-position: 100%;
  background-repeat: no-repeat;
  padding: 7px 28px 6px 12px;
}

.Toastify__toast {
  border-radius: 0;
  color: black;
  font-family: var(--font-family-regular);
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
}

.Toastify__toast strong {
  font-family: var(--font-family-bold);
}

.Toastify__toast p {
  margin: 0;
}

.Toastify__close-button {
  color: #333;
  opacity: 1;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  top: 0;
}

.Toastify__toast-icon {
  margin-bottom: 0.25rem;
  width: 1.5rem;
}

.Toastify__toast--error {
  background-color: var(--color-toast-error-background);
  border: 1px solid var(--color-toast-error);
}

.Toastify__toast--error .Toastify__toast-icon {
  color: var(--color-toast-error);
}

.Toastify__toast--info {
  background-color: var(--color-toast-info-background);
  border: 1px solid var(--color-toast-info);
}

.Toastify__toast--info .Toastify__toast-icon {
  color: var(--color-toast-info);
}

.Toastify__toast--success {
  background-color: var(--color-toast-success-background);
  border: 1px solid var(--color-toast-success);
}

.Toastify__toast--success .Toastify__toast-icon {
  color: var(--color-toast-success);
}

.Toastify__toast--warning {
  background-color: var(--color-toast-warning-background);
  border: 1px solid var(--color-toast-warning);
}

.Toastify__toast--warning .Toastify__toast-icon {
  color: var(--color-toast-warning);
}

.Toastify__toast-body {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .Toastify__toast-container {
    padding: 0;
    right: 0;
    top: 0;
    width: 100%;
  }

  .Toastify__toast {
    margin-bottom: 0;
  }

  .Toastify__toast-body {
    flex: unset;
    flex-direction: row;
    justify-content: center;
    margin: auto;
  }

  .Toastify__toast-icon {
    margin-bottom: 0;
  }
}

.vjs-playback-rate .vjs-playback-rate-value {
  font-size: 11px !important;
  line-height: 2.7 !important;
}
