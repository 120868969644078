.create-button {
  font-size: var(--font-size-small);
  height: auto;
  padding: 11px 13px;
}

.link {
  cursor: pointer;
  width: 100%;
}

.loader {
  margin-top: var(--spacing-xxl);
}

.modal-header {
  align-items: center;
  background-color: var(--color-background-tertiary);
  border-bottom: 1px solid var(--color-border-primary);
  display: flex;
  gap: 20px;
  padding: var(--spacing-medium) 38px;
}

.modal-header h2 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-large);
  margin-bottom: 0;
  text-transform: none;
}

.org-name {
  padding-left: var(--spacing-small);
}

.switch-modal-arrow {
  flex-grow: 1;
  text-align: right;
}
