.buttons button {
  width: 100%;
}

.create-org-header {
  font-family: var(--font-family-regular);
  font-size: 20px;
  margin-bottom: 40px;
  text-transform: uppercase;
}

.image-upload-error {
  padding-top: var(--spacing-small);
  position: unset;
  width: 200px;
}

.image-upload-error p {
  color: var(--color-error-red);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxs);
  letter-spacing: 0.4px;
  line-height: var(--line-height-xxs);
  padding-top: 0.5rem;
}

.loading {
  margin-top: calc(var(--spacing-xxs) * -1);
}

.modal-center {
  padding: 45px;
  text-align: center;
}

.profile-picture-container {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
  position: relative;
}

.text-field input,
.text-field select {
  margin-bottom: var(--spacing-medium);
}

.upload {
  bottom: 10px;
  color: transparent;
  cursor: pointer;
  height: 45px;
  margin-left: 110px;
  overflow: hidden;
  position: absolute;
  width: 45px;
}

.upload::-webkit-file-upload-button {
  visibility: hidden;
}

.upload::before {
  content: url('~assets/icons/camera.svg');
  display: block;
}

.upload:hover::before {
  border-color: var(--color-background);
}

.upload:active::before {
  background: linear-gradient(#e3e3e3, #f9f9f9);
}

.text-field select {
  background-image: url('~assets/icons/arrow-down.svg');
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-right: var(--spacing-small);
}

@media (min-width: 1024px) {
  .modal-center {
    padding: 45px 110px;
  }

  .grid {
    column-gap: var(--spacing-medium);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
