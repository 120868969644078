h1 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xl);
  margin-bottom: var(--spacing-small);
}

.container {
  text-align: center;
}

.image {
  background-image: url('~assets/images/p3po.png');
  background-position: center center;
  background-repeat: no-repeat;
  height: 500px;
}

.title {
  padding-bottom: var(--spacing-medium);
}
