.container {
  display: grid;
  gap: 1rem 2rem;
  grid-template-areas:
    'header -'
    'content triggers';
  grid-template-columns: 1fr auto;
  padding: var(--spacing-large) 0;
}

.header {
  grid-area: header;
  height: var(--graph-tabs-header-height);
  text-align: center;
}

.header h3 {
  font-size: var(--font-size-small);
  margin-bottom: 5px;
  text-transform: uppercase;
}

.header p {
  font-size: 0.8rem;
}

.triggers {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  grid-area: triggers;
}

.triggers button[data-state='inactive'] {
  opacity: 0.5;
}

.content {
  grid-area: content;
}
