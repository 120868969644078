.header {
  background-color: var(--background-color-tertiary);
}

.container {
  align-items: center;
  column-gap: var(--spacing-medium);
  display: flex;
  height: var(--header-height);
  margin: auto;
  max-width: 1240px;
  padding-inline: 10px;
}

.container > div:first-child {
  flex-grow: 1;
}

.full-width .container {
  max-width: none;
  padding: 0 var(--spacing-large);
}

.logo {
  flex-grow: 1;
}

.profile-icon,
.settings-icon {
  cursor: pointer;
}

.switch-org-modal {
  background-color: var(--color-background-tertiary);
  border-radius: var(--border-radius-primary);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 26%);
  padding: 0;
  padding-bottom: 32px;
}

.switch-org-modal li button {
  align-items: center;
  background-color: #131313;
  border-bottom: 1px solid var(--color-border-primary);
  display: flex;
  font-size: var(--font-size-medium);
  padding: 10px 38px;
}

.switch-org-modal li img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.switch-modal-arrow {
  flex-grow: 1;
}

.popover {
  border: none;
  margin-top: var(--spacing-xs);
  min-width: unset;
  padding: 0;
}

@media (max-width: 767px) {
  .popover {
    background-color: #000;
    min-height: 100vh;
  }
}

@media (min-width: 769px) {
  .popover button {
    display: none;
  }
}
