.main {
  width: 600px;
}

.header {
  border-bottom: 1px solid var(--color-border-primary);
  font-family: var(--font-family-bold);
  margin-bottom: var(--spacing-large);
  padding: var(--spacing-small) 0;
  text-transform: uppercase;
}

.description {
  margin-bottom: var(--spacing-large);
}

.assessment {
  align-items: center;
  background-color: var(--color-foreground--4);
  border-color: var(--color-interactive-4);
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-medium);
  padding: var(--spacing-medium);
}

.assessment button {
  font-size: var(--font-size-small);
  height: auto;
  padding: var(--spacing-xs);
  position: static;
}

.attach-btn {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.assign-btn {
  font-family: var(--font-family-regular);
}

/* mobile only */
@media (max-width: 767px) {
  .main {
    width: calc(100vw);
    z-index: 2;
  }
}
