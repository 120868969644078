:root {
  --selected-orgs-modal-spacing-desktop: var(--spacing-xl);
  --selected-orgs-modal-spacing-mobile: var(--spacing-large);
}

.main {
  z-index: 2;
}

.header h2 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xl);
}

.selected-assessments {
  background-color: var(--color-foreground--4);
  border-color: var(--color-interactive-4);
  border-style: solid;
  border-width: 1px 0;
}

.selected-assessments,
.selected-orgs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--spacing-xs);
}

.orgs-list > li {
  line-height: var(--line-height-xxl);
}

.selected-assessments > li:first-child,
.orgs-list-container > p:first-child {
  color: var(--color-text-secondary);
  font-family: var(--font-family-italic);
  font-size: var(--font-size-small);
}

.selected-assessments > li:first-child {
  margin-bottom: var(--spacing-xs);
}

.selected-orgs {
  margin: 0 0 var(--spacing-medium) 0;
}

.selected-orgs > li:first-child {
  color: var(--color-interactive-2);
  margin-right: var(--spacing-small);
  text-transform: uppercase;
}

.orgs-list > li:first-child {
  line-height: var(--line-height-xxl);
  text-transform: uppercase;
}

.orgs-list input[type='checkbox'] {
  margin-right: var(--spacing-medium);
  vertical-align: middle;
}

/* Mobile only */
@media (max-width: 767px) {
  .main {
    grid-template-columns: auto;
    padding: 0;
  }

  .header {
    padding: var(--spacing-large) var(--spacing-large) 0 var(--spacing-large);
  }

  .header h2 {
    margin-bottom: var(--spacing-medium);
  }

  .header button {
    margin-bottom: var(--spacing-large);
    width: 100%;
  }

  .selected-assessments,
  .orgs-list-container {
    padding: var(--spacing-large);
  }
}

/* Tablet and up only */
@media (min-width: 768px) {
  .main {
    align-items: center;
    display: grid;
    gap: var(--spacing-large);
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    padding: 0;
  }

  /* close button */
  .main > button:first-child {
    grid-column: 2;
    padding: var(--spacing-large) var(--spacing-large) 0 var(--spacing-medium);
    position: static;
    width: auto;
  }

  .main > button:first-child svg {
    height: 20px;
    width: 20px;
  }

  /* contains title and attach button */
  .header {
    align-items: center;
    display: flex;
    grid-row: 1;
    justify-content: space-between;
    padding: var(--spacing-large) 0 0 var(--spacing-large2);
  }

  .selected-assessments,
  .orgs-list-container {
    grid-column: 1 / 3;
    padding: 0 var(--spacing-xl);
  }

  .orgs-list-container {
    margin-bottom: var(--spacing-large2);
  }

  .selected-assessments {
    padding-bottom: var(--spacing-large);
    padding-top: var(--spacing-large);
  }

  .selected-assessments-legend {
    display: none;
  }
}
