/* stylelint-disable selector-class-pattern */
.table-main {
  table-layout: fixed;
}

.table-main :global .select {
  width: 50px;
}

.table-main :global .PlayerName {
  width: 250px;
}

.table-main :global .sport {
  width: 130px;
}

.table-main :global .Last {
  width: 120px;
}

.table-main :global .trainingTargets {
  width: 200px;
}

.loading {
  text-align: center;
}

.loading svg {
  margin: 100px;
  position: static;
  transform: none;
}

/* tablet down */
@media (max-width: 1023px) {
  .table-main :global .sport {
    display: none;
  }

  .table-main :global .PlayerName {
    width: 90px;
  }
}

@media (max-width: 767px) {
  .table-main th,
  .table-main td {
    font-size: var(--font-size-small);
    vertical-align: top;
    width: 90px;
  }

  .table-main :global .organizations li {
    white-space: nowrap;
  }

  .table-main :global .PlayerName,
  .table-main :global .organizations li > span:first-child {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .table-main :global .organizations li > span:first-child {
    display: inline-block;
  }

  .table-main :global .select {
    width: 30px;
  }

  .table-main :global .PlayerName {
    width: 90px;
  }

  .table-main :global .Last {
    width: 75px;
  }

  .table-main :global .trainingTargets {
    width: 90px;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .table-main :global .organizations li {
    max-width: 90px;
  }

  .table-main :global .organizations li > span:first-child {
    width: 45px;
  }
}

@media (max-width: 375px) {
  .table-main :global .organizations li {
    max-width: 80px;
  }

  .table-main :global .organizations li > span:first-child {
    width: 36px;
  }

  .table-main :global .PlayerName {
    width: 60px;
  }

  .table-main :global .Last {
    width: 80px;
  }
}
