.overview {
  background-color: var(--color-background-header);
  padding: 0 var(--spacing-large);
}

.flex {
  align-items: center;
  column-gap: var(--spacing-large);
  display: flex;
  margin-bottom: 32px;
}

.header {
  padding-top: var(--spacing-large);
}

.header-text p {
  align-items: center;
  display: flex;
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
  margin-bottom: var(--spacing-xxs);
}

.user-text h1 {
  max-width: 200px;
  overflow-wrap: break-word;
}

.border::before {
  border: 1px solid var(--color-text-primary);
  content: '';
  height: 100%;
  margin: 0 var(--spacing-xxs);
  width: 0;
}

.compare {
  background-color: var(--color-button-action);
  border-radius: var(--border-radius-primary);
  color: var(--color-text-primary);
  font-family: var(--font-family-bold);
  font-size: var(--font-size-medium);
  grid-area: button;
  margin-bottom: var(--spacing-large);
  padding: 20px 23px;
  text-align: center;
  text-transform: uppercase;
}

.icon {
  border-radius: 50%;
  display: block;
  grid-area: icon;
  height: auto;
  max-height: 100px;
  max-width: 100px;
  width: auto;
}

.header h1 {
  font-family: var(--font-family-bold);
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  margin-bottom: 0;
  margin-top: 0;
}

.score {
  border-bottom: solid 0.7px var(--color-border-primary);
  column-gap: var(--spacing-xs);
  display: flex;
  grid-area: score;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 0 20px var(--spacing-large) 20px;
}

.stats div {
  display: flex;
  padding: var(--spacing-large) var(--spacing-xs);
}

.score div:nth-child(2) {
  margin-left: var(--spacing-xl);
}

.stats dt {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.stats dd {
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  text-align: center;
}

.targets dt {
  font-family: var(--font-family-regular);
  font-size: var(--font-size-small);
}

.flex-stats dt,
.header .score dt,
.header .info dt {
  font-family: var(--font-family-regular);
  font-size: var(--font-size-small);
  text-transform: uppercase;
}

.header dl.score {
  font-family: var(--font-family-bold);
  font-size: 80px;
}

.info {
  column-gap: var(--spacing-large);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(--spacing-large);
  padding: 0 20px;
  row-gap: var(--spacing-large);
}

.info dd {
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  white-space: nowrap;
}

.info dd .bold,
.info dd .bold .formatted-height {
  display: inline-block;
  font-family: var(--font-family-bold);
  font-size: 48px;
}

.info dd .bold .formatted-height {
  align-items: flex-start;
  display: flex;
}

.info dd span {
  align-items: center;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: var(--font-size-small);
}

.info dd .bold .formatted-height > span {
  font-size: var(--font-size-xl);
}

.info dd svg {
  margin-left: 0;
  margin-right: var(--spacing-xs);
  width: 12px;
}

.info dd .negative svg {
  transform: rotate(90deg);
}

.stats {
  display: flex;
  flex-direction: column;
}

.stats .row {
  border-top: solid 0.7px var(--color-border-primary);
  display: flex;
  justify-content: center;
  margin: 0 -28px;
  padding: 0 28px;
}

.stats .row div:not(:last-child)::after {
  border: solid 0.7px var(--color-border-primary);
  content: '';
  height: 100%;
  margin-left: var(--spacing-small);
  width: 1px;
}

.targets {
  background-color: var(--color-background-secondary);
  border-bottom: solid 0.5px var(--color-interactive-2);
  border-top: solid 0.5px var(--color-interactive-2);
  grid-area: targets;
  height: max-content;
  margin-bottom: var(--spacing-large);
  padding: 18px 24px;
  position: relative;
}

.targets dd {
  color: var(--surface);
  font-family: var(--font-family-regular);
  font-size: var(--font-size-small);
  line-height: 1.25;
  margin-bottom: var(--spacing-small);
}

.checkbox-row {
  display: flex !important;
}

@media (min-width: 768px) {
  .user-info {
    column-gap: var(--spacing-xl);
    display: grid;
    grid-template-areas:
      'icon text targets'
      'icon score targets';
    grid-template-columns: 156px 1fr 1fr;
  }

  .user-text {
    grid-area: text;
  }

  .header {
    padding: var(--spacing-large);
  }

  .info-grid-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 0;
  }

  .info-grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 0;
  }

  .info div {
    padding: 34px var(--spacing-large);
  }

  .icon {
    max-height: 156px;
    max-width: 156px;
  }

  .header h1 {
    font-size: var(--font-size-xxxl);
    line-height: var(--line-height-xxxl);
    margin-bottom: var(--spacing-large);
  }

  .header-text {
    font-size: var(--font-size-large);
  }

  .header text p {
    margin-bottom: var(--spacing-xs);
  }

  .score {
    border-bottom: none;
    column-gap: var(--spacing-xl);
    padding: 0;
  }

  .compare {
    height: fit-content;
    width: fit-content;
  }

  .flex-stats {
    border-top: solid 0.7px var(--color-border-primary);
    margin: 0 -28px;
    padding: 0 28px;
  }
}

@media (min-width: 768px) and (max-width: 1239px) {
  .info {
    justify-items: center;
  }

  .user-text h1 {
    max-width: 550px;
  }

  .row div {
    padding: var(--spacing-large) var(--spacing-xl) var(--spacing-large) 0;
  }

  .width-control .stats .row div::after {
    margin-left: var(--spacing-xl);
  }
}

@media (min-width: 1025px) {
  .targets {
    border-left: solid 0.5px var(--color-interactive-2);
    border-radius: var(--border-radius-primary);
    border-right: solid 0.5px var(--color-interactive-2);
  }

  .stats dt {
    clip: unset;
    clip-path: unset;
    height: unset;
    margin-bottom: var(--spacing-xs);
    overflow: unset;
    position: unset;
    white-space: unset;
    width: unset;
  }

  .stats .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .stats div {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 18px 30px;
  }

  .stats .row div:not(:last-child) {
    border-right: solid 0.7px var(--color-border-primary);
  }

  .stats div::after {
    display: none;
  }

  .stats .row:not(:first-child) {
    border-bottom: solid 0.7px var(--color-border-primary);
  }
}

@media (min-width: 1240px) {
  .user-info {
    column-gap: var(--spacing-xl);
    grid-template-columns: 160px 1fr 1fr;
  }

  .user-text h1 {
    max-width: 435px;
  }

  .flex {
    column-gap: var(--spacing-xl);
    margin-bottom: 0;
  }

  .flex-stats {
    border-bottom: solid 0.7px var(--color-border-primary);
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    overflow-y: hidden;
    position: relative;
    right: 50%;
    width: 100vw;
  }

  .width-control {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .info {
    border-left: solid 0.7px var(--color-border-primary);
    column-gap: 0;
    padding: 0;
    row-gap: 0;
  }

  .score {
    column-gap: 0;
    justify-content: flex-start;
  }

  .info div {
    align-items: baseline;
    border-right: solid 0.7px var(--color-border-primary);
    display: flex;
    flex-direction: column;
    padding: 48px 18px var(--spacing-medium) 18px;
  }

  .score div:nth-child(2) {
    margin-left: 68px;
  }

  .stats {
    border-right: solid 0.7px var(--color-border-primary);
    margin: 0;
    padding: 0;
  }

  .stats .row {
    margin: 0;
    padding: 0;
  }

  .stats .row:first-child {
    border-top: none;
  }

  .stats .row:last-child {
    border-bottom: none;
  }

  .width-control,
  .user-info {
    margin: 0 auto;
    max-width: 1200px;
    min-width: 1200px;
  }

  .icon {
    justify-self: flex-end;
    max-height: 224px;
    max-width: 224px;
  }
}
