.chart-title {
  font-size: var(--font-size-small);
  text-align: center;
  text-transform: uppercase;
}

.chart-subtext {
  font-size: var(--font-size-xs);
  font-style: italic;
  opacity: 0.6;
  padding-top: 10px;
  text-align: center;
}

.scatter-plot-chart,
.radar-chart {
  height: 400px;
  margin-bottom: var(--spacing-large);
  padding-bottom: var(--spacing-large);
  width: 100%;
}

.bottom-border {
  border-bottom: 1px solid var(--color-border-primary);
}

@media (min-width: 768px) {
  .chart-container {
    background-color: var(--color-background);
    margin: 0;
    padding-top: var(--spacing-large);
  }

  h3 {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-large);
  }
}

@media (min-width: 1025px) {
  .scatter-plot-chart,
  .radar-chart {
    height: 500px;
    margin: 0 auto;
    width: 100%;
  }

  .radar-chart {
    height: 470px;
    padding: 0 var(--spacing-large);
  }
}
