.form-section {
  background-color: var(--color-background--50);
}

.form {
  border-radius: var(--border-radius-primary);
  margin-bottom: var(--spacing-medium);
  padding: var(--spacing-large) var(--spacing-medium);
}

.fieldset {
  display: grid;
  gap: var(--spacing-large);
  margin-bottom: var(--spacing-large);
}

.flex legend {
  color: var(--color-text-secondary);
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-medium);
  text-transform: uppercase;
}

.flex > span {
  align-items: center;
  display: flex;
}

.disabled {
  pointer-events: none;
}

.form-section select {
  background-image: url('~assets/icons/arrow-down.svg');
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 25px;
  padding-right: var(--spacing-small);
}

.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.resend {
  align-items: center;
  display: inline-flex;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-small);
  margin-right: var(--spacing-large);
  text-transform: uppercase;
}

.resend::before {
  content: url('~assets/icons/arrow-right-interactive.svg');
}

.remove {
  float: right;
  font-size: var(--font-size-medium);
  margin-bottom: var(--spacing-large2);
  padding: var(--spacing-medium);
}

@media (min-width: 768px) {
  .form {
    padding: var(--spacing-large2) var(--spacing-xl);
  }

  .fieldset {
    grid-template-columns: 1fr 1fr;
  }

  .address {
    grid-column: span 2;
  }
}
